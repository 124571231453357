import React from "react";
import { Tabs as MuiTabs, Tab as MuiTab } from "@mui/material";
import styled from "styled-components";

function TabWrapper({ children }: { children: React.ReactNode }) {
  return (
    <MuiTabs
      value={false}
      variant='scrollable'
      aria-label='scrollable auto tabs navigation'
      scrollButtons
      allowScrollButtonsMobile
      sx={{
        minHeight: "unset",
        alignItems: "center",
        "& .MuiTabs-flexContainer": {
          gap: "0.25rem",
        },
      }}
    >
      {children}
    </MuiTabs>
  );
}
export default TabWrapper;

export const TabOption = styled(MuiTab)<{
  selected?: boolean;
  color?: string;
  color2?: string;
}>`
  padding: 6px 12px;
  border-radius: 4px;
  outline: none;
  border: none;
  color: ${({ color, theme }) => color ?? theme.text1};
  background-color: ${({ selected, theme, color2 }) =>
    selected ? color2 ?? theme.bg3 : "transparent"};
  text-decoration: none;
  font-weight: 500;

  :hover {
    cursor: pointer;
    background-color: ${({ theme, color2 }) => color2 ?? theme.bg3};
  }

  :focus {
    box-shadow: inset 0 0 0 1pt ${({ theme, color }) => color ?? theme.bg4};
  }
`;
