import React, { useEffect } from "react";
import WidgetBot from "@widgetbot/react-embed";
import { ButtonBasic } from "components/Button";
import config from "config";
import { useUpToMedium } from "hooks/useMediaQueries";
export default function DiscordBot() {
  const isMedium = useUpToMedium();
  useEffect(() => {
    let button: any;
    async function loadCrate() {
      const result = await import("@widgetbot/crate");
      const Crate = await result.cdn();
      button = new Crate({
        server: config.discord.support.server, // discord.gg/amplifidao
        channel: config.discord.support.channel, // start here / help channel
        defer: true,
        location: [isMedium ? -70 : "bottom", "right"],
      });
    }
    loadCrate();
    return () => {
      button && button.hide();
    };
  }, [isMedium]);
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
  };
  return (
    <>
      {/* <WidgetBot server={config.discord.support.server} channel={config.discord.support.channel} /> */}
      {/* <ButtonBasic onClick={handleClick}>Verify discord</ButtonBasic> */}
    </>
  );
}
