import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { useProtocolUpdate } from "../../hooks/useProtocolUpdate";
import { BodyWrapper, LowerBodyWrapper, WhiteHeader } from "pages/AppBody";
// import { useActiveWeb3React } from '../hooks'
import { useActiveProtocol } from "state/governance/hooks";
import Column, { AutoColumn } from "components/Column";
import { Above1080Only, Below1080Only, ExternalLink } from "theme/components";
import Row, { RowBetween, RowFixed } from "components/Row";
import { WrappedListLogo } from "components/governance/styled";
import Dropdown from "components/governance/Dropdown";
import { TYPE } from "theme";
import CampaignList from "components/campaigns/CampaignList";
import CampaignDetails from "components/campaigns/CampaignDetails";
import { useActiveCampaign } from "state/campaigns/hooks";
import CRE8RPriceCard from "components/CRE8RPriceCard";
import { ProfilePopUp } from "components/Profile/ProfilePopUp";
import ClaimButton from "components/ClaimButton/ClaimAGGButton";
import Breadcrumb from "components/Breadcrumb";
import { TokenLogo } from "components/Icons/Icons";
import { Button } from "../../theme";
const LinkWrapper = styled(Button)`
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  color: ${({ theme }) => theme.white};
  font-size: 14px;
  :hover {
    text-decoration: none;
    cursor: pointer;
  }
`;

function TopBar({
  match: {
    params: { protocolID, campaignID },
  },
  history,
}: RouteComponentProps<{ protocolID?: string; campaignID?: string }>) {
  const [activeProtocol] = useActiveProtocol();
  const [activeCampaign] = useActiveCampaign();

  return (
    <AutoColumn gap='0'>
      <AutoColumn>
        {/* if on campaignlist or activeCampaign in redux matches the route */}
        {(campaignID === activeCampaign?.id || !campaignID) && (
          <WhiteHeader>
            <Above1080Only>
              <RowBetween>
                <RowFixed>
                  <WrappedListLogo src={activeProtocol?.logo} />

                  {campaignID ? (
                    <>
                      <TYPE.mediumHeader
                        ml='8px'
                        fontWeight={600}
                        color={"#232323"}
                      >
                        {activeCampaign?.title}
                      </TYPE.mediumHeader>
                      <TYPE.mediumHeader
                        ml='8px'
                        fontWeight={300}
                        color={"#acacac"}
                      >
                        {activeProtocol?.name}
                      </TYPE.mediumHeader>
                    </>
                  ) : (
                    <>
                      <TYPE.mediumHeader
                        ml='8px'
                        fontWeight={600}
                        color={"#232323"}
                      >
                        {activeProtocol?.name}
                      </TYPE.mediumHeader>
                    </>
                  )}
                </RowFixed>
                <Row width='unset' gap='1rem'>
                  <ExternalLink href={"https://amplifi.gg/the-amplifly-wheel/"}>
                    <LinkWrapper>
                      <TokenLogo name='LAGG' size={20} /> &nbsp; Learn about
                      $LAGG{" "}
                    </LinkWrapper>
                  </ExternalLink>
                  <ProfilePopUp />
                  {/* <CRE8RPriceCard /> */}
                </Row>
              </RowBetween>
            </Above1080Only>
            <Below1080Only>
              <Dropdown />
            </Below1080Only>
          </WhiteHeader>
        )}
      </AutoColumn>
    </AutoColumn>
  );
}

export default withRouter(TopBar);
