/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  IReferralStorage,
  IReferralStorageInterface,
} from "../IReferralStorage";

const _abi = [
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "_code",
        type: "bytes32",
      },
    ],
    name: "codeOwners",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_account",
        type: "address",
      },
    ],
    name: "getTraderReferralInfo",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "_code",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "_newAccount",
        type: "address",
      },
    ],
    name: "govSetCodeOwner",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_referrer",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_tierId",
        type: "uint256",
      },
    ],
    name: "setReferrerTier",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "_code",
        type: "bytes32",
      },
    ],
    name: "registerCode",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "_code",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "_newAccount",
        type: "address",
      },
    ],
    name: "setCodeOwner",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_tierId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_totalRebate",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_discountShare",
        type: "uint256",
      },
    ],
    name: "setTier",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_account",
        type: "address",
      },
      {
        internalType: "bytes32",
        name: "_code",
        type: "bytes32",
      },
    ],
    name: "setTraderReferralCode",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export class IReferralStorage__factory {
  static readonly abi = _abi;
  static createInterface(): IReferralStorageInterface {
    return new utils.Interface(_abi) as IReferralStorageInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider,
  ): IReferralStorage {
    const contract = new Contract(
      address,
      _abi,
      signerOrProvider as any,
    ) as unknown;
    return contract as IReferralStorage;
  }
}
