import Column from "components/Column";
import styled from "styled-components";

export const BodyWrapper = styled.div`
  position: relative;
  width: 100%;

  @media (max-width: 1080px) {
    width: 100%;
    margin: 0;
    padding-right: 0;
  }
`;

export const LowerBodyWrapper = styled.div`
  padding-left: 2rem;
  padding-right: 2rem;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  `}
`;

export const WhiteHeader = styled.div`
  padding: 28px;
  background-color: white;
`;

// export const MediumHeaderWrapper = styled.div`
//   display: none;
//   ${({ theme }) => theme.mediaWidth.upToLarge`
//     display: initial;
//   `};
// `;
