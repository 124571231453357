import { BigNumber, utils } from "ethers";
import {
  GetUserActionsQuery,
  GetUserPayoutQuery,
  RewardToken,
} from "generated/graphql";
import { scientificToBigNumber } from "./format";

export const DEFAULT_DECIMALS = 18;
export interface NormalizedPayoutToken {
  symbol: string;
  amountUSD: string;
  amountNum: string;
  address?: string;
  chainId?: number;
  decimals?: number;
}

type NormalizedPayoutTotals = NormalizedPayoutToken;

export function getPayoutsFromUserActions(
  userActions: { user: NonNullable<GetUserActionsQuery["user"]> },
  tokenPrices: { [tokenSymbol: string]: number },
) {
  const userPayouts = userActions.user.actions.map(({ reward }) => {
    return reward;
  });

  const payoutTokens: Array<RewardToken> = [];
  if (userPayouts.length == 0) {
    return new Map();
  }
  for (const payout of userPayouts) {
    if (!payout) {
      continue;
    }
    for (const payoutToken of payout.tokens) {
      // @ts-ignore
      payoutTokens.push(payoutToken);
    }
  }
  console.log({ payoutTokens });
  return normalizePayouts(payoutTokens, tokenPrices);
}

function normalizePayouts(
  payoutTokens: Array<RewardToken>,
  tokenPrices: { [tokenSymbol: string]: number },
): Map<string, NormalizedPayoutTotals> {
  const normalizedPayoutTokens: Array<NormalizedPayoutToken> = [];
  for (const payoutToken of payoutTokens) {
    normalizedPayoutTokens.push(normalizePayout(payoutToken, tokenPrices));
  }
  const normalizedPayoutTokensMap = new Map<string, NormalizedPayoutTotals>();
  for (const npt of normalizedPayoutTokens) {
    const oldValue = {
      ...npt,
      amountUSD: "0",
      amountNum: "0",
    };
    if (normalizedPayoutTokensMap.has(npt.symbol)) {
      oldValue.amountNum = normalizedPayoutTokensMap.get(npt.symbol)!.amountNum;
      oldValue.amountUSD = normalizedPayoutTokensMap.get(npt.symbol)!.amountUSD;
    }

    normalizedPayoutTokensMap.set(npt.symbol, {
      ...npt,
      amountUSD: BigNumber.from(oldValue.amountUSD)
        .add(BigNumber.from(npt.amountUSD))
        .toString(),
      amountNum: BigNumber.from(oldValue.amountNum)
        .add(BigNumber.from(npt.amountNum))
        .toString(),
    });
  }
  return normalizedPayoutTokensMap;
}

function normalizePayout(
  payoutToken: RewardToken,
  tokenPrices: { [tokenSymbol: string]: number },
): NormalizedPayoutToken {
  if (!payoutToken.token) {
    throw "token address decimal is wrong";
  }
  const {
    amountNum,
    amountUSD,
    token: { address, decimals, name, symbol, chainId },
  } = payoutToken;
  const decimalsUsed = decimals || DEFAULT_DECIMALS;
  let normalizedAmountNum: string;
  let normalizedAmountUSD: string;
  if (!tokenPrices[symbol]) {
    throw `Missing ${symbol} in tokenPrices`;
  }
  if (amountNum !== null && amountNum !== undefined && amountUSD === null) {
    normalizedAmountNum = amountNum;
    normalizedAmountUSD = BigNumber.from(amountNum)
      .mul(
        BigNumber.from(
          scientificToBigNumber(
            tokenPrices[symbol] * 10 ** (30 - decimalsUsed),
          ),
        ),
      )
      .toString();
  } else if (
    amountNum === null &&
    amountUSD !== undefined &&
    amountUSD !== null
  ) {
    normalizedAmountNum = utils
      .parseUnits(
        BigNumber.from(amountUSD)
          .div(
            scientificToBigNumber(
              tokenPrices[symbol] * 10 ** (30 - decimalsUsed),
            ),
          )
          .toString(),
        decimalsUsed,
      )
      .toString();
    normalizedAmountUSD = amountUSD;
  } else {
    throw "Error in schema, please contact the database administrator";
  }
  return {
    symbol: symbol,
    amountNum: normalizedAmountNum,
    amountUSD: normalizedAmountUSD,
    address: address || undefined,
    chainId: chainId || undefined,
    decimals: decimalsUsed,
  };
}
