import React, { useState } from "react";
import Toggle from "components/Toggle";
import { useLeaderboard } from "hooks/useLeaderboard";
import styled from "styled-components";
import EmptyProfile from "../../assets/images/emptyprofile.png";
import {
  useActiveProtocol,
  useFilterActive,
} from "../../state/governance/hooks";
import { FETCHING_INTERVAL } from "../../state/governance/reducer";
import {
  BlankInternalLink,
  OnlyAboveLarge,
  OnlyAboveSmall,
  TYPE,
} from "../../theme";
import { shortenAddress } from "../../utils";
import Card from "../Card";
import { AutoColumn } from "../Column";
import {
  EmptyWrapper,
  RoundedProfileImage,
  WrappedListLogo,
} from "../governance/styled";
import { LoadingRows } from "../Loader";
import Row, { AutoRow, RowBetween, RowFixed } from "../Row";
import { useAllIdentities, useTwitterProfileData } from "state/social/hooks";
import useENSName from "hooks/useENSName";
import { nameOrAddress } from "utils/getName";
import { RewardData } from "data/leaderboard";
import {
  Link,
  RouteComponentProps,
  useLocation,
  withRouter,
} from "react-router-dom";
import { getAddress } from "ethers/lib/utils";
import { Info } from "components/QuestionHelper";
import { MouseoverTooltip } from "components/Tooltip";
import { TabOptionNotScrollable } from "components/governance/Tabs";

const ColumnLabel = styled(TYPE.darkGray)`
  white-space: no-wrap;
  font-size: 15px;
`;

const NoWrap = styled(TYPE.black)`
  white-space: nowrap;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const DataRow = styled.div<{ columns?: number }>`
  display: grid;
  grid-template-columns: ${({ columns }) =>
    columns === 4 ? "1fr 132px 1fr 1fr 1fr" : "1fr 132px 1fr 1fr"};
  // grid-column-gap: 1rem;

  position: relative;
  margin: 6px 0;
  border-left: 3px solid transparent;

  &:first-child {
    :hover {
      border-left: 3px solid transparent;
    }
  }

  ${({ theme, columns }) => theme.mediaWidth.upToExtraLarge`
    grid-template-columns: ${
      columns === 4 ? "1fr 0.5fr 1fr 1fr 1fr" : "1fr 1fr 1fr 1fr"
    };
    //  > *:nth-child(2){
    //   display: none;
    // }
  `};

  ${({ theme, columns }) => theme.mediaWidth.upToLarge`
  grid-template-columns: ${
    columns === 4 ? "1.5fr 1fr 1fr 1.2fr" : "2fr 1fr 1.2fr"
  };
  `};

  ${({ theme, columns }) => theme.mediaWidth.upToDunks`
    grid-template-columns: ${columns === 4 ? "2fr 1fr 1fr 1fr" : "2fr 1fr 1fr"};
    padding: 0 0.5rem;
    > *:nth-child(2) {
      display: none;
    }
    `};

  ${({ theme, columns }) => theme.mediaWidth.upToMedium`
    grid-template-columns: ${columns === 4 ? "1fr 1fr 1fr" : "1fr 1fr"};
    margin: 0;
    padding: 0 1rem;
    > *:nth-child(3) {
      display: none;
    }
  `};

  ${({ theme, columns }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 1fr 1fr;
    margin: 0;
    padding: 0 1rem;
    > *:nth-child(4) {
      ${columns === 4 && "display: none;"}
    }
  `};
`;

const BodyDataRow = styled(DataRow)`
  background-color: ${({ theme }) => theme.white};
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 8px;
  transition: 0.5s;
  padding-left: 18px;
  padding-right: 18px;
`;

const AccountLinkGroup = styled(AutoRow)`
  :hover {
    /* opacity: 0.5; */
    /* border-radius: 8px; */
  }

  flex-wrap: nowrap;
`;

const VoteText = styled(NoWrap)`
  width: 120px;
  color: white;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
  `};
`;

const FixedRankWidth = styled.div`
  width: 12px;
  text-align: right;
  margin-right: 0px;
`;

const HiddenBelow1080 = styled.span`
  @media (max-width: 1080px) {
    display: none;
  }
`;

const ResponsiveText = styled(TYPE.black)`
  color: white;
  font-weight: 500px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
  `};
`;

const WhiteHover = styled.div`
  color: #0a0a0a;
  transition: 0.5s;
`;

export const Break = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.bg3};
  height: 1px;
  margin: 12px 0;
`;

function LeaderboardList({
  match: {
    params: { protocolID, campaignID, leaderboardID },
  },
}: RouteComponentProps<{
  protocolID: string;
  campaignID: string;
  leaderboardID?: string;
}>) {
  const [filter, setFilter] = useFilterActive();
  const [activeProtocol] = useActiveProtocol();
  const location = useLocation();
  const [page] = useState(1);
  const { data, isLoading, isError, error, consts, leaderboards } =
    useLeaderboard(protocolID, campaignID, leaderboardID);
  const [allIdentities] = useAllIdentities();

  const PayoutRow = ({ d, i }: { d: RewardData; i: number }) => {
    const userRewards = d;
    const address = userRewards.address;
    const formattedRewards = userRewards.formattedRewards;

    const twitterData = useTwitterProfileData(
      allIdentities?.[getAddress(address)]?.twitter?.handle, //need getAddress because address with caps will not work here
    );
    const imageURL = twitterData?.profileURL ?? undefined;

    const { ENSName } = useENSName(address ?? undefined);
    const name = nameOrAddress(address, allIdentities, true, false, ENSName);
    if (filter && !twitterData) {
      return null;
    }
    const numOfColumns = d.rewards.length;
    return (
      <AutoColumn>
        <BodyDataRow columns={numOfColumns}>
          <AutoRow gap='10px' style={{ flexWrap: "nowrap" }}>
            <HiddenBelow1080>
              <FixedRankWidth>
                <NoWrap>
                  <WhiteHover>
                    {(page - 1) * FETCHING_INTERVAL + (i + 1)}
                  </WhiteHover>
                </NoWrap>
              </FixedRankWidth>
            </HiddenBelow1080>
            <BlankInternalLink
              as='div'
              to={`/leaderboard/${protocolID}/${campaignID}`}
              // to={"/profile/" + activeProtocol?.id + "/" + address}
            >
              <AccountLinkGroup gap='10px' width='initial'>
                <HiddenBelow1080>
                  {imageURL ? (
                    <RoundedProfileImage>
                      <img src={imageURL} alt='profile' />
                    </RoundedProfileImage>
                  ) : (
                    <WrappedListLogo
                      src={EmptyProfile}
                      alt='profile'
                      style={{ opacity: "0.2" }}
                    />
                  )}
                </HiddenBelow1080>
                <AutoColumn gap='6px'>
                  <ResponsiveText>
                    <WhiteHover>{name}</WhiteHover>
                  </ResponsiveText>
                  {twitterData ? (
                    <WhiteHover
                      style={{
                        fontSize: 12,
                        opacity: "0.6",
                        fontWeight: 300,
                      }}
                    >
                      {shortenAddress(address)}
                    </WhiteHover>
                  ) : (
                    <WhiteHover style={{ opacity: "0.6", fontSize: "12px" }}>
                      {/* {true ? "👤 EOA" : " 📜 Smart Contract"} */}
                    </WhiteHover>
                  )}
                </AutoColumn>
              </AccountLinkGroup>
            </BlankInternalLink>
          </AutoRow>
          {formattedRewards.map((value, i) => {
            if (formattedRewards.length - 1 === i) {
              return (
                <Row style={{ justifyContent: "flex-end" }}>
                  <VoteText textAlign='end'>
                    <WhiteHover>{value}</WhiteHover>
                  </VoteText>
                </Row>
              );
            } else {
              return <NoWrap textAlign='end'>{value}</NoWrap>;
            }
          })}
        </BodyDataRow>
      </AutoColumn>
    );
  };
  const Tabs = () => (
    <div className='flex gap-4 pb-8'>
      {leaderboards.length > 1
        ? leaderboards.map(({ tabName }, i) => {
            return (
              <TabOptionNotScrollable
                key={tabName}
                as={Link}
                to={
                  "/leaderboard/" +
                  protocolID +
                  "/" +
                  campaignID +
                  "/" +
                  i.toString()
                }
                selected={
                  (location.pathname[location.pathname.length - 1] ===
                    i.toString() &&
                    location.pathname[location.pathname.length - 2] === "/") ||
                  (i === 0 &&
                    location.pathname[location.pathname.length - 2] !== "/")
                }
              >
                <TYPE.main>{tabName}</TYPE.main>
              </TabOptionNotScrollable>
            );
          })
        : null}
    </div>
  );
  if (isLoading) {
    return (
      <Card padding={"0"}>
        <Tabs />
        <LoadingRows>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </LoadingRows>
      </Card>
    );
  }
  if (isError) {
    return (
      <Card padding='20px'>
        <EmptyWrapper>
          <TYPE.body style={{ marginBottom: "8px" }}>
            Error happened! Contact AmpliFi DAO
          </TYPE.body>
          <TYPE.subHeader>
            <i>{error as React.ReactNode}</i>
          </TYPE.subHeader>
        </EmptyWrapper>
      </Card>
    );
  }
  if (!data || !consts) {
    return (
      <Card padding='20px'>
        <EmptyWrapper>
          <TYPE.body style={{ marginBottom: "8px" }}>No payouts yet.</TYPE.body>
          <TYPE.subHeader>
            <i>Rewards for participating will appear here</i>
          </TYPE.subHeader>
        </EmptyWrapper>
      </Card>
    );
  }
  return (
    <Card padding='0'>
      <Tabs />
      <OnlyAboveLarge>
        <RowBetween style={{ marginBottom: "32px", alignItems: "flex-start" }}>
          <div>
            <TYPE.body fontSize='16px' fontWeight='600'>
              {consts.title}
            </TYPE.body>
            {consts.description && (
              <TYPE.body fontSize='10px' fontWeight={"400"} paddingTop={"10px"}>
                {consts.description}
              </TYPE.body>
            )}
          </div>
          <OnlyAboveSmall>
            <RowFixed>
              <Toggle isActive={filter} toggle={() => setFilter(!filter)} />
            </RowFixed>
          </OnlyAboveSmall>
        </RowBetween>
      </OnlyAboveLarge>
      <AutoColumn gap='0'>
        {data ? (
          <>
            {data.data.length === 0 ? (
              <>
                {" "}
                <Card padding='0px'>
                  <EmptyWrapper>
                    <TYPE.body style={{ marginBottom: "8px" }}>
                      No one made it here.
                    </TYPE.body>
                    <TYPE.subHeader>
                      Try completing this campaign&apos;s tasks to get first
                      place!
                    </TYPE.subHeader>
                  </EmptyWrapper>
                </Card>
              </>
            ) : (
              <>
                <DataRow columns={data.headers.length}>
                  <ColumnLabel>Rank</ColumnLabel>
                  {data.headers.map((each) => (
                    <ColumnLabel textAlign='end' key={each.header}>
                      {each.info ? (
                        <MouseoverTooltip text={each.info}>
                          {each.header}
                        </MouseoverTooltip>
                      ) : (
                        each.header
                      )}
                    </ColumnLabel>
                  ))}
                </DataRow>
                <Break />
                {data.data
                  .sort(
                    (a, b) =>
                      parseFloat(b.defaultSortValue) -
                      parseFloat(a.defaultSortValue),
                  )
                  .map((j, i) => {
                    return <PayoutRow i={i} d={j} key={i} />;
                  })}
              </>
            )}
          </>
        ) : (
          <LoadingRows>
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
          </LoadingRows>
        )}
      </AutoColumn>
    </Card>
  );
}

export default withRouter(LeaderboardList);
