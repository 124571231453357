import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import config from "config";

export const payoutsClient = new ApolloClient({
  link: new HttpLink({
    uri: config.api.main,
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
});

export const referralClient = payoutsClient;
