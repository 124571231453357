import React from "react";
import styled from "styled-components";
import { TYPE } from "theme";
import AGGLogo from "components/AGGLogo";

const WentWrongWrapper = styled.div<{ height?: string }>`
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => (height ? height : "100%")};
  width: 100%;
`;

export default function AmpliFiWentWrong(props: {
  error?: unknown;
  height?: string;
}) {
  return (
    <WentWrongWrapper height={props.height}>
      <TYPE.black>
        We are terribly sorry, something went wrong {props.error}
      </TYPE.black>
      <AGGLogo />
    </WentWrongWrapper>
  );
}
