import React from "react";
import LeaderboardList from "components/campaigns/LeaderboardList";
import { RouteComponentProps } from "react-router-dom";
import { AutoColumn } from "../components/Column";
import Dropdown from "../components/governance/Dropdown";
import { WrappedListLogo } from "../components/governance/styled";
import Tabs from "../components/governance/Tabs";
import { RowFixed } from "../components/Row";
import { useProtocolUpdate } from "../hooks/useProtocolUpdate";
import { useActiveProtocol } from "../state/governance/hooks";
import { TYPE } from "../theme";
import { BodyWrapper, LowerBodyWrapper, WhiteHeader } from "./AppBody";

import { Above1080Only, Below1080Only } from "../theme/components";
import TopBar from "components/TopBar";
import { useActiveCampaign } from "state/campaigns/hooks";

export default function Leaderboard({
  match: {
    params: { protocolID, campaignID },
  },
}: RouteComponentProps<{ protocolID?: string; campaignID?: string }>) {
  // if valid protocol id passed in, update global active protocol
  useProtocolUpdate(protocolID);

  const [activeProtocol] = useActiveProtocol();
  const [activeCampaign] = useActiveCampaign();

  return (
    <BodyWrapper>
      <AutoColumn gap='1rem'>
        <TopBar />
        {campaignID && campaignID === activeCampaign?.id && (
          <Tabs history={history} />
        )}
        <LowerBodyWrapper>
          <LeaderboardList />
        </LowerBodyWrapper>
      </AutoColumn>
    </BodyWrapper>
  );
}
