export const formatProtocolID_campaignID_leaderboardID = (
  protocolID: string,
  campaignID: string,
  leaderboardID?: string,
) => {
  if (leaderboardID) {
    return protocolID + "/" + campaignID + "/" + leaderboardID;
  }
  return protocolID + "/" + campaignID;
};
