import React from "react";
import { LoadingRows } from "components/Loader";
import Tabs, { TabsData } from "components/Tabs";
import { useCampaign } from "hooks/useCampaign";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";
import { AppDispatch } from "../../state";
import { useActiveProtocol } from "../../state/governance/hooks";
import { SUPPORTED_PROTOCOLS } from "../../state/governance/reducer";
import Column, { AutoColumn } from "../Column";
import CampaignContent from "./CampaignContent";
import CampaignOverview from "./CampaignOverview";
import CampaignBanner from "./CampaignBanner";
import IncentivesKPI, { LoadingIncentivesKPIs } from "./IncentivesKPI";
import Highlights from "./Highlights";
import { OnlyBelowUpToDunks, TYPE } from "../../theme";
import {
  IncomingHighlight,
  IncentivesAndKPIs,
} from "../../components/campaigns/typesIncentivesKPIs";
import { useActiveCampaign } from "state/campaigns/hooks";
import Referral from "components/referral";

const Wrapper = styled.div<{ backgroundColor?: string }>``;

export const CardSection = styled(AutoColumn)<{ disabled?: boolean }>`
  padding: 1rem;
  z-index: 1;
  opacity: ${({ disabled }) => disabled && "0.4"};
`;

//not sure we need this one, but can break without it
const StyledDiv = styled.div`
  background-color: white;
  padding: 32px;
  border-radius: 16px;
  margin-bottom: 16px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  padding: 16px;
  `}
`;

function CampaignDetails({
  location: { pathname },
  match: {
    params: { protocolID, campaignID },
  },
  history,
}: RouteComponentProps<{
  protocolID: string;
  campaignID: string;
  tabID?: string;
}>) {
  // if valid protocol id passed in, update global active protocol
  const dispatch = useDispatch<AppDispatch>();
  const [, setActiveProtocol] = useActiveProtocol();
  const [activeCampaign] = useActiveCampaign();

  useEffect(() => {
    if (protocolID && Object.keys(SUPPORTED_PROTOCOLS).includes(protocolID)) {
      setActiveProtocol(SUPPORTED_PROTOCOLS[protocolID]);
    }
  }, [dispatch, protocolID, setActiveProtocol]);

  const { amplifiCampaignsTabData, uriToRouteMap, page, tabUri } = useCampaign(
    protocolID,
    pathname,
    campaignID,
  );
  let incentivesBonusKPIsData: IncentivesAndKPIs | undefined,
    highlightsData: IncomingHighlight[] | undefined;
  if (activeCampaign && activeCampaign.iak && activeCampaign.highlights) {
    incentivesBonusKPIsData = activeCampaign.iak;
    highlightsData = activeCampaign.highlights;
  } else if (
    page &&
    page.data &&
    page.data.amplifiCampaignFields &&
    page.data.amplifiCampaignFields.highlights &&
    page.data.amplifiCampaignFields.incentivesbonuskpis &&
    page.data.amplifiCampaignFields.incentivesbonuskpis.kPIs &&
    page.data.amplifiCampaignFields.incentivesbonuskpis.incentives
  ) {
    incentivesBonusKPIsData =
      page.data.amplifiCampaignFields.incentivesbonuskpis;
    highlightsData = page.data.amplifiCampaignFields.highlights;
  }

  useEffect(() => {
    const twttr = (window as any).twttr;
    if (page && page.data.content && twttr.widgets) {
      console.log(twttr);
      twttr.widgets.load();
    }
    if (activeCampaign && activeCampaign?.content && twttr.widgets) {
      twttr.widgets.load();
    }
  }, [page && page.data.content, activeCampaign && activeCampaign?.content]);
  if (campaignID !== activeCampaign?.id) {
    return null;
  }
  return (
    <Wrapper>
      <Column gap='10px' style={{ width: "100%" }}>
        <StyledDiv>
          <CampaignBanner />
          {activeCampaign && activeCampaign?.content && (
            <div
              dangerouslySetInnerHTML={{
                __html: activeCampaign?.content,
              }}
            />
          )}

          {!page ? (
            <LoadingIncentivesKPIs />
          ) : incentivesBonusKPIsData && highlightsData ? (
            <>
              <IncentivesKPI
                data={incentivesBonusKPIsData}
                tokenIconsSize={20}
              />
              <Highlights data={highlightsData} />
            </>
          ) : (
            <CampaignContent />
          )}
          <OnlyBelowUpToDunks>
            <Referral campaignID={campaignID} />
          </OnlyBelowUpToDunks>
          {amplifiCampaignsTabData.length > 0 && (
            <div style={{ paddingBottom: 10 }}>
              <Tabs
                data={amplifiCampaignsTabData}
                value={tabUri}
                onChange={() => {
                  //optional
                }}
                onClick={(uri: string) => {
                  history.replace(
                    uriToRouteMap[uri].find((route) =>
                      route.includes(campaignID),
                    ) || uriToRouteMap[uri][0],
                  );
                }}
              />
            </div>
          )}
          <div>
            {page ? (
              <>
                {page.type === "SubPage" ? (
                  <>
                    {(() => {
                      const Component = page.data.component;
                      return <Component />;
                    })()}
                  </>
                ) : page.type === "WPACFPage" ? (
                  <CampaignOverview />
                ) : (
                  <>
                    {/* <Break /> */}
                    {!page || !page.data ? (
                      <LoadingRows>
                        <div />
                        <div />
                        <div />
                        <div />
                      </LoadingRows>
                    ) : (
                      // WPContentPage
                      <CampaignContent content={page.data.content} />
                    )}
                  </>
                )}
              </>
            ) : (
              <LoadingRows>
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
              </LoadingRows>
            )}
            {page && page.error && <div>Error loading content</div>}
          </div>
        </StyledDiv>
      </Column>
      {/* </ProposalInfo> */}
    </Wrapper>
  );
}

export default withRouter(CampaignDetails);
