import { useQuery } from "@tanstack/react-query";
import { leaderboardMapper, RewardList } from "data/leaderboard";
import { fetchGMXRefCodes } from "data/leaderboard/gmx";
import { useActiveWeb3React } from "hooks";
import { useEffect, useState } from "react";
import { formatProtocolID_campaignID_leaderboardID } from "utils/campaigns";

export function useLeaderboard(
  protocolID: string,
  campaignID: string,
  leaderboardID?: string,
) {
  const id = formatProtocolID_campaignID_leaderboardID(
    protocolID,
    campaignID,
    "",
  );
  const leaderboard = leaderboardMapper[id];
  const index =
    leaderboardID && parseInt(leaderboardID) < leaderboard.length
      ? parseInt(leaderboardID)
      : 0;
  const { isLoading, isError, data, error } = useQuery(
    ["leaderboard", id, leaderboardID],
    async () => {
      if (!leaderboard || leaderboard.length == 0) {
        return null;
      }

      const res = await leaderboard[index].fetch();
      return res;
    },
    {
      // enabled: typeof leaderboard?.title === "string", //if there is leaderboard data, then run query
    },
  );

  // TODO: possibly need to refactor this
  const consts =
    leaderboard && leaderboard.length > 0
      ? {
          title: leaderboard[index].title,
          description: leaderboard[index].description,
        }
      : undefined;
  return { isLoading, isError, data, error, consts, leaderboards: leaderboard };
}

export function useRefCodesStat(protocolID: string, address?: string | null) {
  // TODO: cant do mapping to leaderboard object because payoutinfo doesnt have campaign and tabid in its route
  const { isLoading, isError, data, error, refetch } = useQuery(
    ["refCodes", protocolID, address],
    async () => {
      if (!address) {
        return null;
      }
      return await fetchGMXRefCodes(address);
    },
    {
      // enabled: typeof leaderboard?.title === "string", //if there is leaderboard data, then run query
    },
  );
  return { isLoading, isError, data, error, refetch };
}
