import {
  GMXReferralClientAvalanche,
  GMXReferralClientArbitrum,
} from "apollo/client";
import config from "config";
import { ethers } from "ethers";
import {
  ReferralDataQuery,
  ReferrerCodesGmxQuery,
  ReferrerRewardsGmxQuery,
} from "generated/gmx-referral/graphql";
import {
  REFERRAL_DATA_QUERY,
  REFERRER_CODES_QUERY,
  REFERRER_REWARDS_QUERY,
} from "apollo/queries/gmx-referral";
import { RewardData, RewardList, RewardCodeMapData } from "..";
import { TbBrandDiscord } from "react-icons/tb";
import GMXTier1 from "assets/svg/gmx-tier-1.svg";
import GMXTier2 from "assets/svg/gmx-tier-2.svg";
import GMXTier3 from "assets/svg/gmx-tier-3.svg";
import { WrappedListLogo } from "components/governance/styled";
import React from "react";
import styled from "styled-components";
import { ChainId } from "../../../constants";
import { ApolloClient } from "@apollo/client";

const TierLevel = styled(WrappedListLogo)`
  width: 20px;
  height: 20px;
  border-radius: 0;
`;

const Tier = (tier: "1" | "2" | "3") => {
  switch (tier) {
    case "3":
      return <TierLevel src={GMXTier3} />;
    case "2":
      return <TierLevel src={GMXTier2} />;
    case "1":
    default:
      return <TierLevel src={GMXTier1} />;
  }
};

const formatUSD = (discountUsd: string) => {
  return (parseFloat(discountUsd) / 1e30).toString();
};

//TODO: Make snapshots of this data and save it to the database (this should be scheduled on the server where the server directly queries the subgraph)
function fetchGMXReferralFactory(chainId: ChainId) {
  return () => {
    switch (chainId) {
      case ChainId.Arbitrum:
        return fetchGMXReferrals(GMXReferralClientArbitrum, chainId);
      case ChainId.Avalanche:
        return fetchGMXReferrals(GMXReferralClientAvalanche, chainId);

      default:
        throw "Chain id must be Avalanche or Arbitrum";
    }
  };
}
async function fetchGMXReferrals(
  client: ApolloClient<any>,
  chainId: ChainId,
): Promise<RewardList> {
  if (chainId !== ChainId.Arbitrum && chainId !== ChainId.Avalanche)
    throw "Chain Id must be Avalanche or Arbitrum";
  const headers = [
    { header: "Tier" },
    { header: "Traders Referred" },
    { header: "Total Rebates" },
    { header: "$LAGG" },
  ];
  const gmxReferralData = await client.query<ReferralDataQuery>({
    query: REFERRAL_DATA_QUERY,
    variables: {
      typeIds: ["1", "2"],
      account: config.multisigWallets[chainId],
      timestamp: parseInt((Date.now() / 1000).toFixed(0)),
      referralTotalStatsId: `total:0:${config.multisigWallets[chainId]}`,
    },
  });
  const filteredData = gmxReferralData.data.referralCodes.filter(
    ({ oldOwner }) => oldOwner,
  );
  const oldOwnerToAggregateRewardData = new Map<
    string,
    Omit<RewardData, "formattedRewards">
  >();

  //populates map
  filteredData.forEach((d) => {
    if (!d.oldOwner) {
      throw "oldOwner should be filtered";
    }
    const gmxOfficialData = gmxReferralData.data.referrerTotalStats.find(
      (o) => o.referralCode === d.code,
    );
    let registeredReferralsCount: string, rebateUsd;
    const referralCode = d.code;
    if (gmxOfficialData) {
      registeredReferralsCount = gmxOfficialData.registeredReferralsCount;
      rebateUsd = formatUSD(
        (
          parseInt(gmxOfficialData.totalRebateUsd) / 2 -
          parseInt(gmxOfficialData.discountUsd) / 2
        ).toString(),
      );
    } else {
      registeredReferralsCount = "0";
      rebateUsd = "0";
    }

    const existingRewardData = oldOwnerToAggregateRewardData.get(d.oldOwner);
    const newRewardData = [
      "1",
      registeredReferralsCount,
      rebateUsd,
      (parseFloat(rebateUsd) / 2).toString(),
    ]; // note: This is assuming that all users are GMX tier 1, so 7.5% GMX Tier 1 and 3.75% LAGG
    if (existingRewardData) {
      if (existingRewardData.rewards.length !== headers.length) {
        throw "the initial set of the data for the map is probably wrong, make sure when u set the ().rewards that it is the same length as the header";
      }
      const reducedRewards = newRewardData.map((reward, i) =>
        (
          parseFloat(reward) + parseFloat(existingRewardData.rewards[i])
        ).toString(),
      );
      oldOwnerToAggregateRewardData.set(d.oldOwner, {
        address: d.oldOwner,
        defaultSortValue: reducedRewards[2], //aggregated discountedUsd
        rewards: reducedRewards,
      });
    } else {
      oldOwnerToAggregateRewardData.set(d.oldOwner, {
        address: d.oldOwner,
        defaultSortValue: rebateUsd,
        rewards: newRewardData,
      });
    }
  });
  const data = [...oldOwnerToAggregateRewardData].map(([key, value]) => {
    const aggregatedRegisteredReferralsCount: any = value.rewards[1];
    const aggreatedDiscountUsd = value.rewards[2];
    const aggreatedLAGG = value.rewards[3];
    return {
      address: value.address,
      rewards: value.rewards,
      defaultSortValue: value.defaultSortValue,
      formattedRewards: [
        Tier("1"),
        aggregatedRegisteredReferralsCount,
        "~$" + parseFloat(aggreatedDiscountUsd).toFixed(4),
        "~$" + parseFloat(aggreatedLAGG).toFixed(4),
      ],
    };
  });

  return {
    headers: headers,
    data: data,
  };
}

export async function fetchGMXRefCodes(
  account: string,
): Promise<RewardCodeMapData> {
  const title = "RefCodesWithReward";
  const description = "";
  const headers = [{ header: "Code" }, { header: "Reward" }];
  const gmxRefCodesForAccount =
    await GMXReferralClientArbitrum.query<ReferrerCodesGmxQuery>({
      query: REFERRER_CODES_QUERY,
      variables: {
        multisig: config.multisigWallets[42161],
        referrer: account,
      },
    });
  const codesArray = gmxRefCodesForAccount.data.referralCodes;
  const resMap = new Map<string, number>();
  await Promise.all(
    codesArray.map(async ({ code }) => {
      const gmxRewardForRefCode =
        await GMXReferralClientArbitrum.query<ReferrerRewardsGmxQuery>({
          query: REFERRER_REWARDS_QUERY,
          variables: {
            referralCode_in: [code],
          },
        });
      resMap.set(
        ethers.utils.parseBytes32String(code),
        gmxRewardForRefCode.data.referrerStats.reduce(
          (sum: any, each: any) =>
            (sum += parseFloat(
              formatUSD(
                (
                  (parseFloat(each.totalRebateUsd) -
                    parseFloat(each.discountUsd)) *
                  1.5
                ).toString(), // assuming gmx tier 1 and combining lagg
              ),
            )),
          0,
        ),
      );
    }),
  );
  return {
    headers: headers,
    data: resMap,
    title,
    description,
  };
}

export default [
  {
    tabName: "Arbitrum Tiers",
    title: "Arbitrum GMX referrals",
    description: "",
    fetch: fetchGMXReferralFactory(ChainId.Arbitrum),
    fetchUsersRefCodes: fetchGMXRefCodes,
  },
  {
    tabName: "Avalanche Tiers",
    title: "Avalanche GMX referrals",
    description: "",
    fetch: fetchGMXReferralFactory(ChainId.Avalanche),
    fetchUsersRefCodes: fetchGMXRefCodes,
  },
];
