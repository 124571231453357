import React from "react";

/*
note that this code is kinda legacy in await of React 18 with concurrent suspense
but we still have React 16.8 and also not planning to update so I decided to use it

there's a package for functional package but i don't see a reason for additional
package for that

according to react docs:
Error boundaries work like a JavaScript catch {} block, but for components.
Only class components can be error boundaries.
*/

interface ErrorBoundaryProps {
  location: string;
}

export default class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  { hasError: boolean }
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(
    error: unknown,
  ): { hasError: boolean } | null {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    //   You can also log the error to an error reporting service
    //   logErrorToMyService(error, errorInfo);
    console.error("Catched error: ", error);
    console.error("React error info: ", errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <h1>Oi! {this.props.location} page has just broken.</h1>
          <p>
            We have just started and if you happen to see this, please report it
            in our{" "}
            <a
              style={{ color: "#FE532D" }}
              href='https://discord.gg/amplifidao'
            >
              AmpliFi Discord
            </a>
          </p>
        </>
      );
    }

    return this.props.children;
  }
}
