export function getUTMTags(search: string) {
  function getUrlParameter(name: string) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    const results = regex.exec(search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  const urlParamsToForward = {
    utm_content: "",
    utm_campaign: "",
    utm_source: "",
  };

  //populates urlParamsToForward with terms in the window location
  Object.keys(urlParamsToForward).forEach((name) => {
    const subname = getUrlParameter(name);
    if (subname) {
      //@ts-ignore
      urlParamsToForward[name] = subname;
    }
  });
  return urlParamsToForward;
}
