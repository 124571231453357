import React from "react";
import { CopyBtn } from "components/AccountDetails/Copy";
import { useActiveWeb3React } from "hooks";
import { useActiveCampaign, useReferralLink } from "state/campaigns/hooks";
import styled from "styled-components";
// gutenberg basic styles
// import "@wordpress/block-library/build-style/common.css";
import "@wordpress/block-library/build-style/style.css";
// import "@wordpress/block-library/build-style/theme.css";
import { ApplicationModal } from "state/application/actions";
import { useToggleModal } from "state/application/hooks";
import { ButtonBasic } from "components/Button";
import { TYPE } from "theme";
import TwitterIntentButton from "./TwitterIntentButton";
import useTwitterIntent from "hooks/useTwitterIntent";
import { useWeb3React } from "@web3-react/core";
import { useArcx } from "hooks/Arcx";
import { MouseoverTooltip } from "components/Tooltip";

export const Break = styled.div`
  width: 800px;
  background-color: ${({ theme }) => theme.bg3};
  height: 1px;
  margin: 0;
`;

const ReferralWrapper = styled.div`
  display: flex;

  padding: 10;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 10px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
   flex-wrap: wrap;
  `};
`;

export default function ReferralLinks() {
  const [activeCampaign] = useActiveCampaign();
  const acrx = useArcx();
  const referralLink = useReferralLink();
  const { account } = useActiveWeb3React();
  const twitterIntentUrl = useTwitterIntent();
  return (
    <>
      {/* <AutoColumn> */}
      {activeCampaign &&
        (referralLink && account ? (
          <>
            <ReferralWrapper>
              <MouseoverTooltip
                placement='top'
                text={
                  activeCampaign
                    ? activeCampaign.baseUrl.replace("?", "")
                    : "Your ref link"
                }
              >
                <div
                  onClick={() => {
                    if (!activeCampaign || !activeCampaign.id) return;
                    acrx?.event("COPY_LINK for" + activeCampaign.id);
                  }}
                >
                  <CopyBtn
                    toCopy={"https://" + referralLink}
                    numOfLinks={twitterIntentUrl ? 2 : 1}
                  ></CopyBtn>
                </div>
              </MouseoverTooltip>
              {twitterIntentUrl && <TwitterIntentButton />}
            </ReferralWrapper>
          </>
        ) : (
          <></>
        ))}
      {/* </AutoColumn> */}
    </>
  );
}
