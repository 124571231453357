// put it here for some time
import { ethers } from "ethers";

export const AVALANCHE = 43114;
export const ARBITRUM = 42161;
export const MAINNET = 1;

interface NetworkMetadata {
  chainId: string;
  chainName: string;
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: number;
  };
  rpcUrls: string | string[];
  blockExplorerUrls: string | string[];
}
interface SupportedNetworkMetadata {
  [index: number]: NetworkMetadata;
}

export const CHAIN_NAMES_MAP = {
  [MAINNET]: "Ethereum",
  [ARBITRUM]: "Arbitrum",
  [AVALANCHE]: "Avalanche",
};

export const NETWORK_METADATA: SupportedNetworkMetadata = {
  //   [MAINNET]: {
  //     chainId: "0x" + MAINNET.toString(16),
  //     chainName: "BSC",
  //     nativeCurrency: {
  //       name: "BNB",
  //       symbol: "BNB",
  //       decimals: 18,
  //     },
  //     rpcUrls: BSC_RPC_PROVIDERS,
  //     blockExplorerUrls: ["https://bscscan.com"],
  //   },
  [ARBITRUM]: {
    chainId: "0x" + ARBITRUM.toString(16),
    chainName: "Arbitrum",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: "https://arb1.arbitrum.io/rpc",
    blockExplorerUrls: [getExplorerUrl(ARBITRUM)],
  },
  [AVALANCHE]: {
    chainId: "0x" + AVALANCHE.toString(16),
    chainName: "Avalanche C-Chain",
    nativeCurrency: {
      name: "AVAX",
      symbol: "AVAX",
      decimals: 18,
    },
    rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
    blockExplorerUrls: [getExplorerUrl(AVALANCHE)],
  },
};

export function getExplorerUrl(chainId: number) {
  if (chainId === 3) {
    return "https://ropsten.etherscan.io/";
  } else if (chainId === 42) {
    return "https://kovan.etherscan.io/";
  } else if (chainId === MAINNET) {
    return "https://etherscan.io/";
  } else if (chainId === ARBITRUM) {
    return "https://arbiscan.io/";
  } else if (chainId === AVALANCHE) {
    return "https://snowtrace.io/";
  }
  return "https://etherscan.io/";
}

export const addNetwork = async (metadata: NetworkMetadata) => {
  if (window.ethereum) {
    await (window.ethereum as any).request({
      method: "wallet_addEthereumChain",
      params: [metadata],
    });
  }
};

export const switchNetwork = async (chainIdReq: number) => {
  if (window.ethereum) {
    const { ethereum } = window;
    const hexChainId = ethers.utils.hexValue(chainIdReq);
    try {
      await (ethereum as any).request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: hexChainId,
          },
        ],
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if ((switchError as any).code === 4902) {
        try {
          return await addNetwork(NETWORK_METADATA[chainIdReq]);
        } catch (e) {
          // This error code indicates user rejected action.
          if ((e as any).code === 4001) {
            // toast.error("You rejected chain add");
            return "user rejected";
          }
        }
      }
      if ((switchError as any).code === 4001) {
        // toast.error("You rejected chain switch");
        return "user rejected";
      }
    }
  } else {
    return;
  }
};

export const getObjKey = (obj: Record<string, string>, value: string) => {
  return Object.keys(obj).find((key) => obj[key] === value);
};

export const getChainName = (
  chainId: number | undefined,
): string | undefined => {
  if (!chainId) return undefined;
  if (Object.keys(CHAIN_NAMES_MAP).includes(chainId.toString())) {
    // @ts-ignore
    return CHAIN_NAMES_MAP[chainId.toString()];
  } else {
    return undefined;
  }
};
