import React, { HTMLProps, useCallback } from "react";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { darken } from "polished";
import { ArrowLeft, X } from "react-feather";

export const Above1080Only = styled.span`
  display: initial;
  @media (max-width: 1080px) {
    display: none;
  }
`;

export const Below1080Only = styled.span`
  display: none;
  @media (max-width: 1080px) {
    display: initial;
  }
`;

// want to show the ref buttons in tabs over upToDunks and show them in the main page
export const OnlyBelowUpToDunks = styled.div`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToDunks`
  display: initial;
`};
`;

export const OnlyAboveUpToDunks = styled.div`
  display: initial;
  ${({ theme }) => theme.mediaWidth.upToDunks`
 display: none; 
`};
`;

export const OnlyAboveExtraLarge = styled.div`
  display: initial;
  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
    display: none;
  `};
`;

export const OnlyAboveLarge = styled.div`
  display: initial;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    display: none;
  `};
`;

export const OnlyAboveMedium = styled.div`
  display: initial;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `};
`;

export const OnlyAboveSmall = styled.div`
  display: initial;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`;

export const OnlyAboveExtraSmall = styled.div`
  display: initial;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`;

export const OnlyBelowSmall = styled.div`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: initial;
  `};
`;

export const Button = styled.button.attrs<
  { warning: boolean },
  { backgroundColor: string }
>(({ warning, theme }) => ({
  backgroundColor: warning ? theme.red1 : theme.primary1,
}))`
  padding: 1rem 2rem 1rem 2rem;
  border-radius: 3rem;
  cursor: pointer;
  user-select: none;
  font-size: 1rem;
  border: none;
  outline: none;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ theme }) => theme.white};
  width: 100%;

  :hover {
    background-color: ${({ backgroundColor }) => darken(0.05, backgroundColor)};
  }

  :focus {
    outline-style: solid;
    outline-color: ${({ theme }) => darken(0.05, theme.secondary1)};
  }

  :active {
    background-color: ${({ backgroundColor }) => darken(0.1, backgroundColor)};
  }

  :disabled {
    background-color: ${({ theme }) => theme.bg1};
    color: ${({ theme }) => theme.text4};
    cursor: auto;
  }
`;

export const CloseIcon = styled(X)<{ onClick?: () => void }>`
  cursor: pointer;

  :active {
    stroke: ${({ theme }) => theme.text1};
  }
`;

// A button that triggers some onClick result, but looks like a link.
export const LinkStyledButton = styled.button<{ disabled?: boolean }>`
  border: none;
  text-decoration: none;
  background: none;

  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  color: ${({ theme, disabled }) => (disabled ? theme.text2 : theme.primary1)};
  font-weight: 500;

  :hover {
    text-decoration: ${({ disabled }) => (disabled ? null : "underline")};
  }

  :focus {
    outline: none;
    text-decoration: ${({ disabled }) => (disabled ? null : "underline")};
  }

  :active {
    text-decoration: none;
  }
`;

// An internal link from the react-router-dom library that is correctly styled
export const StyledInternalLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.primary1};
  font-weight: 400;

  :hover {
    text-decoration: underline;
  }

  :focus {
    outline: none;
    text-decoration: underline;
  }

  :active {
    text-decoration: none;
  }
`;

export const NoColorInternalLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.black};
  font-weight: 500;
  :hover {
    text-decoration: underline;
  }
  :focus {
    outline: none;
    text-decoration: underline;
  }
  :active {
    text-decoration: none;
  }
`;

export const BlankInternalLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.black};
  font-weight: 500;

  :hover {
    cursor: pointer;
  }

  :focus {
    outline: none;
  }

  :active {
    text-decoration: none;
  }

  & > * {
    text-decoration: none;
  }
`;

const StyledLink = styled.a<{ color?: string }>`
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme, color }) => color ?? theme.primary1};
  font-weight: 500;

  :hover {
    text-decoration: underline;
  }

  :focus {
    outline: none;
    text-decoration: underline;
  }

  :active {
    text-decoration: none;
  }
`;

const rotateImg = keyframes`
  0% {
    transform: perspective(1000px) rotateY(0deg);
  }

  100% {
    transform: perspective(1000px) rotateY(360deg);
  }
`;

export const UniTokenAnimated = styled.img`
  animation: ${rotateImg} 5s cubic-bezier(0.83, 0, 0.17, 1) infinite;
  padding: 2rem 0 0 0;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
`;

/**
 * Outbound link that handles firing google analytics events
 */
export function ExternalLink({
  target = "_blank",
  href,
  rel = "noopener noreferrer",
  ...rest
}: Omit<HTMLProps<HTMLAnchorElement>, "as" | "ref" | "onClick"> & {
  href: string;
  additionalClickListener?: () => void;
}) {
  const { additionalClickListener } = rest;
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      // don't prevent default, don't redirect if it's a new tab
      if (target === "_blank" || event.ctrlKey || event.metaKey) {
        ReactGA.outboundLink({ label: href }, () => {
          console.debug("Fired outbound link event", href);
        });
      } else {
        event.preventDefault();
        // send a ReactGA event and then trigger a location change
        ReactGA.outboundLink({ label: href }, () => {
          window.location.href = href;
        });
      }
      if (additionalClickListener) {
        additionalClickListener();
      }
    },
    [href, target, additionalClickListener],
  );
  return (
    <StyledLink
      target={target}
      rel={rel}
      href={href}
      onClick={handleClick}
      {...rest}
    />
  );
}

export const SolidSectionBreak = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.black};
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.img`
  animation: 2s ${rotate} linear infinite;
  width: 16px;
  height: 16px;
`;

const BackArrowLink = styled(StyledInternalLink)`
  color: ${({ theme }) => theme.text1};
`;
export function BackArrow({ to }: { to: string }) {
  return (
    <BackArrowLink to={to}>
      <ArrowLeft />
    </BackArrowLink>
  );
}

export const BackArrowSimple = styled(ArrowLeft)`
  :hover {
    cursor: pointer;
  }
`;

export const CustomLightSpinner = styled(Spinner)<{ size: string }>`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
`;

export const HideSmall = styled.span`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`;

// styles
export const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: ".";
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: ".";
    }
    33% {
      content: "..";
    }
    66% {
      content: "...";
    }
  }
`;

export const GreenIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > * {
    width: 16px;
    stroke: ${({ theme }) => theme.green1};
  }
`;

export const RedIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > * {
    width: 16px;
    stroke: ${({ theme }) => theme.red2};
  }
`;

export const LoadingFlag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.blue1};
  color: ${({ theme }) => theme.blue1};
  padding: 4px 8px;
  border-radius: 10px;
  font-size: 11px;

  :hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;
