import React from "react";
import styled, { keyframes } from "styled-components";
import { TYPE } from "theme";

const scale = keyframes`
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
`;

const Wrapper = styled.div<{ height?: string }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => (height ? height : "100%")};
`;

const AmpliFiLoaderSVG = styled.svg`
  animation: 1s ${scale} linear infinite;
`;

export function AmpliFiLoader(props: { height?: string }) {
  return (
    <Wrapper height={props.height}>
      <TYPE.mediumHeader>Preparing content</TYPE.mediumHeader>
      <AmpliFiLoaderSVG
        width='42'
        height='45'
        viewBox='0 0 42 45'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_580_3378)'>
          <g filter='url(#filter0_b_580_3378)'>
            <path
              d='M2.5092e-05 23.988C-0.0067012 28.3363 1.33904 32.5785 3.84987 36.1239L5.29613 32.6044C4.01001 30.2584 3.26481 27.6531 3.11536 24.9802C2.9659 22.3073 3.41599 19.6349 4.43251 17.1594C5.44904 14.6839 7.00613 12.4685 8.98925 10.676C10.9724 8.88349 13.331 7.55958 15.8918 6.80165L17.3363 3.28388C12.4793 4.13897 8.07854 6.68363 4.90774 10.4704C1.73694 14.2572 -0.000822375 19.0436 2.5092e-05 23.988V23.988ZM40.8317 17.2931L39.1658 21.6056L38.773 22.6101C38.8073 23.0689 38.8244 23.5276 38.8244 23.9863C38.826 27.261 37.9319 30.4734 36.2395 33.2742C34.5471 36.0749 32.1211 38.3567 29.2254 39.8714C26.3298 41.386 23.0753 42.0755 19.816 41.8649C16.5568 41.6542 13.4175 40.5515 10.7398 38.6767L8.16636 40.6342C11.7276 43.3816 16.075 44.9091 20.5677 44.9916C25.0603 45.0742 29.4606 43.7073 33.1199 41.0926C36.7791 38.4778 39.5038 34.7535 40.8922 30.4686C42.2807 26.1837 42.2595 21.5649 40.8317 17.2931V17.2931ZM23.2947 3.10498L24.6672 6.46106C26.6291 6.87777 28.5063 7.62518 30.2189 8.67149L35.1942 8.56484C31.9141 5.51275 27.7424 3.59865 23.2947 3.10498V3.10498Z'
              fill='#FE532D'
            />
          </g>
          <path
            d='M36.6656 10.082L32.3251 10.1749L30.0948 10.223L32.6682 13.887L27.5952 17.7385L22.8806 6.17718L21.5768 2.98452L20.3553 0L19.0994 3.05677L17.7424 6.36468L6.30093 34.2316L4.94045 37.5396L2.78906 42.7775L6.94772 39.6158L9.46967 37.6995L25.5073 25.5069L27.976 31.5275H33.2464L29.5321 22.4467L35.5127 17.9037L37.7259 21.0464L38.2852 19.5963L40.0008 15.1772L42.0115 9.96674L36.6656 10.082ZM14.2391 27.8911L20.3724 12.9788L23.5806 20.8005L14.2391 27.8911Z'
            fill='#FE532D'
          />
        </g>
        <defs>
          <filter
            id='filter0_b_580_3378'
            x='-16'
            y='-12.895'
            width='73.918'
            height='73.8901'
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix' />
            <feGaussianBlur in='BackgroundImage' stdDeviation='8' />
            <feComposite
              in2='SourceAlpha'
              operator='in'
              result='effect1_backgroundBlur_580_3378'
            />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_backgroundBlur_580_3378'
              result='shape'
            />
          </filter>
          <clipPath id='clip0_580_3378'>
            <rect width='42' height='45' fill='white' />
          </clipPath>
        </defs>
      </AmpliFiLoaderSVG>
    </Wrapper>
  );
}
