import React, { useState, useEffect } from "react";
import Modal from "components/Modal";
import { ButtonPrimarySmall } from "components/referral/GMXReferralModal";
import styled from "styled-components";
import { TYPE } from "theme";
import { RowBetween } from "components/Row";
import { AutoColumn } from "components/Column";
import Ampy from "../../assets/images/ampy.png";
import "../../theme/extraFonts.css";

const StyledBugModal = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  padding-left: 40px;
  align-items: flex-start;
`;

const AmpyWrapper = styled.img`
  float: right;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `}
`;

function BugModal() {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    const bugModalStatus = localStorage.getItem("bugModalStatus");
    if (!bugModalStatus) {
      setIsOpen(true);
      localStorage.setItem("bugModalStatus", "visited");
    }
  }, []);
  return (
    <Modal isOpen={isOpen} onDismiss={() => setIsOpen(false)}>
      <StyledBugModal>
        <h1
          style={{
            fontFamily: "'Poppins', sans-serif",
            marginTop: 0,
            marginBottom: "1rem",
          }}
        >
          GM and Welcome
        </h1>
        <RowBetween align='center'>
          <AutoColumn gap='8px'>
            <TYPE.mediumHeader>
              We are excited to have you on board!
            </TYPE.mediumHeader>
            <TYPE.custom fontSize={14} fontWeight={400}>
              We just launched AmpliFi. If you encounter any bugs or have
              suggestions, feel free to drop us a message in our{" "}
              <a
                style={{ color: "#FE532D", textDecoration: "none" }}
                href='https://discord.gg/amplifidao'
              >
                Discord
              </a>
              . Your feedback will help us to ship better products in the
              future.
            </TYPE.custom>
            <TYPE.custom fontSize={14} fontWeight={400}>
              Thank you!
            </TYPE.custom>
          </AutoColumn>
          <AmpyWrapper src={Ampy} />
        </RowBetween>
        <ButtonPrimarySmall
          onClick={() => setIsOpen(false)}
          style={{ width: "56px", marginTop: "8px" }}
        >
          Got it!
        </ButtonPrimarySmall>
      </StyledBugModal>
    </Modal>
  );
}

export default BugModal;
