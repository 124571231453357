import { ApolloClient, useQuery as useQueryApollo } from "@apollo/client";
import { useQuery as useQueryTan } from "@tanstack/react-query";
import { RAW_REFERRAL_QUERY } from "apollo/queries";
import {
  HighlightStat,
  RecentActivityData,
  RecentActivityList,
} from "data/dashboard";
import { RewardList } from "data/leaderboard";
import { RawReferralsQuery } from "generated/graphql";
import { useEffect, useState } from "react";
import { isAddress, shortenAddress } from "utils";

import { Network, Alchemy } from "alchemy-sdk";

interface CampaignNameMapper {
  [index: string]: string;
}
const campaignNameMapper: CampaignNameMapper = {
  "gmx-meta-referral": "GMX Meta Referral",
  "gmx-tier-1": "GMX Tier 1",
  "gmx-tier-2": "GMX Tier 2",
  "gmx-tier-3": "GMX Tier 3",
  "beets-boosted-bribes": "Boosted Bribes",
  "pixel-guild-onboarding": "Pixels guild Onboarding",
  "pixels-guild": "Pixels guild",
  "first-chainbet-campaign": "ChainBet Tier 1",
  "gearbox-kol": "GEARBOX V2",
  "amplifi-instigators": "AmpliFi Instigators",
};

export function useDashboard (
  protocolID: string,
  campaignID: string,
  tabID?: string,
) {
  const [cleanData, setCleanData] = useState<RecentActivityList>({
    highlights: [],
    recentActivity: [],
    title: "Dashboard",
    description: "",
  });
  const recentActivity = useQueryApollo<RawReferralsQuery>(RAW_REFERRAL_QUERY, {
    fetchPolicy: "cache-and-network",
    pollInterval: 1000,
    variables: {
      take: 50,
      where: {
        slug: campaignID,
      },
      orderBy: { timestamp: "desc" },
    },
    onCompleted: data => {
      const recentActivity: Array<RecentActivityData> = [];
      if (data.rawReferrals) {
        for (const rawReferral of data.rawReferrals) {
          const {
            refereeAddress,
            referrerAddress,
            timestamp,
            id,
          } = rawReferral;
          let description = "";
          if (!timestamp) continue;
          if (!refereeAddress || !isAddress(refereeAddress)) continue;
          if (
            referrerAddress &&
            isAddress(refereeAddress) &&
            isAddress(referrerAddress)
          ) {
            description = `${shortenAddress(
              refereeAddress,
            )} was referred by ${shortenAddress(
              referrerAddress,
            )} visiting this campaign (${campaignNameMapper[campaignID]}).`;
          } else if (isAddress(refereeAddress)) {
            description = `${shortenAddress(
              refereeAddress,
            )} visited this campaign (${campaignNameMapper[campaignID]}).`;
          }
          recentActivity.push({
            id: id,
            description: description,
            icon: "",
            timestamp: parseInt(timestamp),
          });
        }
      }
      setCleanData(c => ({
        recentActivity: recentActivity,
        highlights: c.highlights,
        title: c.title,
      }));
    },
  });
  const highlights = useQueryTan(
    [],
    async () => {
      // Optional Config object, but defaults to demo api-key and eth-mainnet.
      const settings = {
        apiKey: process.env.REACT_APP_ETHEREUM_ALCHEMY_KEY, // Replace with your Alchemy API Key.
        network: Network.ETH_MAINNET, // Replace with your network.
      };

      const alchemy = new Alchemy(settings);

      // Print total NFT count returned in the response:
      const res: any = await alchemy.nft.getOwnersForContract(
        "0xf7fce9411a01f7b2dee146d3961aa4a602ea2c80",
      );

      if (res && res.owners) {
        setCleanData(c => ({
          highlights: [
            {
              icon: "BiWalletAlt",
              formattedStat: res.owners.length || "-",
              stat: res.owners.length || 0,
              title: "Owners",
            },
          ],
          recentActivity: c.recentActivity,
          title: c.title,
          description: c.description,
        }));
      }
      return null;
    },
    {
      refetchInterval: 1000,
    },
  );
  // TODO: this is a hotfix. Need a longer term solution per campaign to configure campaigns
  if (campaignID.includes("gmx")) cleanData.highlights = [];
  return {
    data: cleanData,
    recentActivity,
    highlights,
    isError: recentActivity.error && highlights.isError,
    error: recentActivity.error && highlights.isError,
    isLoading: recentActivity.loading || highlights.isLoading,
  };
}
