import { useEffect, useState } from "react";
import { useRefCodesStat } from "./useLeaderboard";

export function useExistingRefCode(
  account: string | null | undefined,
): [
  string | undefined,
  React.Dispatch<React.SetStateAction<string | undefined>>,
] {
  const [localCode, setLocalCode] = useState<string>();
  if (!account) {
    setLocalCode(undefined);
  }
  const refCodesStat = useRefCodesStat("GMX", account?.toLowerCase());

  useEffect(() => {
    if (localStorage) {
      const _localCode = localStorage.getItem("GMX code");

      if (_localCode) {
        setLocalCode(_localCode);
        return;
      }
    }
    if (refCodesStat) {
      const [_localCode] = [
        ...(refCodesStat.data?.data.keys() ?? [null]),
      ].slice(-1);
      if (_localCode) {
        setLocalCode(_localCode);
        return;
      }
    }
  }, [localStorage, refCodesStat]);

  return [localCode, setLocalCode];
}
