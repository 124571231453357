import React, { useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import { BodyWrapper, LowerBodyWrapper, WhiteHeader } from "./AppBody";
import { useActiveProtocol } from "../state/governance/hooks";
import { RouteComponentProps } from "react-router-dom";
import { useActiveWeb3React } from "../hooks";
import Card, { WhiteCard } from "../components/Card";
import { useProtocolUpdate } from "../hooks/useProtocolUpdate";
import styled from "styled-components";
import Row, { RowBetween, AutoRow, RowFixed } from "../components/Row";
import { AutoColumn } from "../components/Column";
import EmptyProfile from "../assets/images/emptyprofile.png";
import {
  RoundedProfileImage,
  WrappedListLogo,
  ProposalStatusSmall,
  EmptyWrapper,
} from "../components/governance/styled";
import {
  getTwitterProfileLink,
  getEtherscanLink,
  shortenAddress,
  isAddress,
} from "../utils";
import {
  TYPE,
  ExternalLink,
  GreenIcon,
  RedIcon,
  StyledInternalLink,
  OnlyAboveSmall,
  Above1080Only,
  Below1080Only,
} from "../theme";
import {
  useIdentity,
  useTwitterProfileData,
  useAllIdentities,
} from "../state/social/hooks";
import Loader, { LoadingRows } from "../components/Loader";
import CopyHelper from "../components/AccountDetails/Copy";
import { useIsEOA } from "../hooks/useIsEOA";
import useENS from "../hooks/useENS";
import { nameOrAddress } from "../utils/getName";
import {
  useUserPayout,
  usePricesUSD,
  useActiveCampaign,
} from "state/campaigns/hooks";
import { BigNumber, utils } from "ethers";
import { DEFAULT_DECIMALS, getPayoutsFromUserActions } from "utils/payouts";
import { TokenLogo } from "components/Icons/Icons";
import { SUPPORTED_PROTOCOLS } from "state/governance/reducer";
import { ButtonPrimary } from "components/Button";
import { GetUserActionsQuery } from "generated/graphql";
import Select from "components/Dropdown";
import CRE8RPriceCard from "components/CRE8RPriceCard";
import Breadcrumb from "components/Breadcrumb";
import { ChevronLeft } from "react-feather";
import Tabs from "components/governance/Tabs";
import Dropdown from "../components/governance/Dropdown";
import TopBar from "components/TopBar";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import { useRefCodesStat } from "../hooks/useLeaderboard";
import { nFormatter, scientificToBigNumber } from "utils/format";
import { RewardCodeMap, RewardCodeMapData } from "data/leaderboard";

interface Total {
  amountUSD: number;
  amountNum: string;
}

type Breakdown = Array<{
  symbol: string;
  total: Total;
}>;

const ButtonAction = styled(ButtonPrimary)`
  font-size: 14px;
`;

const ArrowWrapper = styled(StyledInternalLink)`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 24px;
  color: ${({ theme }) => theme.text1};

  a {
    color: ${({ theme }) => theme.text1};
    text-decoration: none;
  }
  :hover {
    text-decoration: none;
    cursor: pointer;
  }
`;

const DataRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 1fr 1fr;
  `};
`;

export const Break = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.bg4};
  height: 1px;
`;

const ResponsiveDataText = styled(TYPE.black)`
  font-size: 20px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
  `};
`;

const ResponsiveBodyText = styled(TYPE.black)`
  font-size: 16px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
  `};
`;

const DisplayOnlyAboveSmall = styled.span`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`;

const Placeholder = styled.div`
  border-radius: 12px;
  background: grey;
  color grey;
`;

function localNumber(val: number) {
  return parseFloat(parseFloat(val.toString()).toFixed(0)).toLocaleString();
}

function PayoutInfo({
  match: {
    params: { protocolID, address, campaignID },
  },
  history,
}: RouteComponentProps<{
  protocolID: string;
  address?: string;
  campaignID?: string;
}>) {
  // if valid protocol id passed in, update global active protocol
  useProtocolUpdate(protocolID);

  const { chainId, account } = useActiveWeb3React();
  const addressToDisplay = address || account;

  const [activeProtocol] = useActiveProtocol();
  const [activeCampaign] = useActiveCampaign();

  const formattedAddress = isAddress(addressToDisplay || undefined);

  const isEOA = useIsEOA(addressToDisplay || undefined);

  // get social data from Sybil list
  const identity = useIdentity(addressToDisplay);
  const twitterHandle = identity?.twitter?.handle;
  const twitterData = useTwitterProfileData(twitterHandle);
  const [allIdentities] = useAllIdentities();

  // ens name if they have it
  const ensName = useENS(formattedAddress ? formattedAddress : null)?.name;

  const nameShortened = nameOrAddress(
    formattedAddress ? formattedAddress : undefined,
    allIdentities,
    true,
    undefined,
    ensName,
  );

  const userPayoutResponse = useUserPayout(addressToDisplay || undefined);
  const userPayouts = userPayoutResponse?.data;
  const pricesUSD = usePricesUSD();

  const userTokens = useMemo<
    | {
        totalUSD: BigNumber;
        breakdown: Breakdown;
      }
    | undefined
  >(() => {
    if (!userPayouts || !userPayouts.user || pricesUSD === undefined)
      return undefined;
    let totalUSD = BigNumber.from(0);
    const breakdown: Breakdown = [];
    const payoutsSum = getPayoutsFromUserActions(
      userPayouts as { user: NonNullable<GetUserActionsQuery["user"]> },
      pricesUSD,
    );
    for (const payout of payoutsSum) {
      const p = payout[1];
      breakdown.push({
        symbol: p.symbol,
        total: {
          amountNum: p.amountNum,
          amountUSD: p.amountUSD,
        },
      });
      totalUSD = totalUSD.add(BigNumber.from(p.amountUSD));
    }
    return {
      totalUSD: BigNumber.from(userPayouts.totalEarnings?.totalUSD || "0"),
      breakdown,
    };
  }, [userPayouts, pricesUSD]);

  const options: Array<{ value: string; label: string }> = [
    { label: "All", value: "all" },
    { label: "Promoter", value: "referrer" },
    { label: "User", value: "referee" },
  ];

  const [selected, setSelected] = useState<{ value: string; label: string }>();
  const actionsFiltered =
    userPayouts &&
    userPayouts.user &&
    userPayouts.user.actions.length > 0 &&
    userPayouts.user.actions.filter(({ allowedAction }) => {
      if (!selected || selected.value == "all") return true;
      if (allowedAction.type == selected.value) return true;
      return false;
    });
  return (
    <BodyWrapper>
      <AutoColumn gap='1rem'>
        <TopBar />
        {campaignID && campaignID === activeCampaign?.id && (
          <Tabs history={history} />
        )}
        <LowerBodyWrapper>
          <ErrorBoundary location='Payouts'>
            {addressToDisplay ? (
              <AutoColumn gap='lg'>
                <WhiteCard>
                  <RowBetween>
                    <AutoRow gap='10px'>
                      {twitterData?.profileURL ? (
                        <RoundedProfileImage>
                          <img src={twitterData.profileURL} alt='profile' />
                        </RoundedProfileImage>
                      ) : (
                        <WrappedListLogo src={EmptyProfile} />
                      )}
                      <AutoColumn gap='2px'>
                        <RowFixed>
                          <ExternalLink
                            href={
                              twitterHandle
                                ? getTwitterProfileLink(twitterHandle)
                                : getEtherscanLink(
                                    chainId || 1,
                                    formattedAddress || addressToDisplay,
                                    "address",
                                  )
                            }
                          >
                            <TYPE.black>
                              {nameShortened === formattedAddress
                                ? ensName ?? formattedAddress
                                : nameShortened}
                            </TYPE.black>
                          </ExternalLink>
                          {!twitterHandle && (
                            <CopyHelper
                              toCopy={formattedAddress || addressToDisplay}
                            />
                          )}
                        </RowFixed>
                        {twitterHandle ||
                        nameShortened !== shortenAddress(addressToDisplay) ? (
                          <RowFixed>
                            <ExternalLink
                              href={getEtherscanLink(
                                chainId || 1,
                                formattedAddress || addressToDisplay,
                                "address",
                              )}
                            >
                              <TYPE.black fontSize='12px'>
                                {shortenAddress(addressToDisplay)}
                              </TYPE.black>
                            </ExternalLink>
                            <CopyHelper
                              toCopy={formattedAddress || addressToDisplay}
                            />
                          </RowFixed>
                        ) : (
                          <TYPE.black fontSize='12px'>
                            {isEOA === true
                              ? "👤 EOA"
                              : isEOA === false && "📜 Smart Contract"}
                          </TYPE.black>
                        )}
                      </AutoColumn>
                    </AutoRow>
                  </RowBetween>
                </WhiteCard>
                <WhiteCard>
                  <RowBetween style={{ alignItems: "flex-start" }}>
                    <AutoColumn gap='sm'>
                      <TYPE.main fontSize='14px'>{`Total Earnings`}</TYPE.main>
                      <ResponsiveDataText>
                        {/* TODO: something is very wrong here */}
                        {userTokens ? (
                          <>
                            ~$
                            {nFormatter(
                              parseFloat(
                                utils.formatUnits(
                                  userTokens.totalUSD.toString(),
                                  30,
                                ),
                              ),
                              2,
                            )}
                          </>
                        ) : userPayouts ? (
                          "~$0"
                        ) : (
                          <Loader />
                        )}
                      </ResponsiveDataText>
                    </AutoColumn>
                    <AutoColumn>
                      <RowFixed
                        gap='10px'
                        style={{
                          margin: "auto 0 auto 0",
                        }}
                      >
                        {/* <a style={{ padding: 10 }} href=''>
                    Link
                  </a> */}
                        <ButtonAction disabled={true}>
                          Claim all rewards
                        </ButtonAction>
                      </RowFixed>
                    </AutoColumn>
                  </RowBetween>
                </WhiteCard>
                <WhiteCard>
                  <AutoColumn gap='lg'>
                    <RowBetween>
                      <TYPE.main fontSize='16px'>Payout History</TYPE.main>
                      <Select
                        options={options}
                        value={selected}
                        onChange={setSelected}
                      />
                    </RowBetween>
                    <Break />
                    {userPayouts &&
                    userPayouts.user &&
                    userPayouts.user.actions.length > 0 &&
                    actionsFiltered ? (
                      actionsFiltered.map(
                        (
                          { allowedAction: { description }, reward, timestamp },
                          i,
                        ) => {
                          if (!reward) return null;
                          if (!userPayouts.user) return null;
                          const { campaign, tokens, status } = reward;
                          return (
                            <div key={i}>
                              <RowBetween style={{ alignItems: "flex-start" }}>
                                <AutoColumn>
                                  <RowFixed>
                                    <AutoColumn style={{ paddingRight: 10 }}>
                                      <WrappedListLogo
                                        src={
                                          (
                                            Object.values(
                                              SUPPORTED_PROTOCOLS,
                                            ).find(
                                              ({ id }) =>
                                                id.toLowerCase() ===
                                                campaign.protocolName.toLowerCase(),
                                            ) as any
                                          ).logo
                                        }
                                        color={"black"}
                                      />
                                    </AutoColumn>
                                    <AutoColumn
                                      gap='sm'
                                      style={{ maxWidth: "500px" }}
                                      justify='flex-start'
                                    >
                                      {/* <StyledInternalLink
                                  to={
                                    "/proposals/" +
                                    activeProtocol?.id +
                                    "/" +
                                    "proposal id"
                                  }
                                > */}
                                      <ResponsiveBodyText
                                        style={{ maxWidth: "240px" }}
                                      >
                                        {description}
                                      </ResponsiveBodyText>
                                      {/* </StyledInternalLink> */}
                                      <RowFixed>
                                        <ProposalStatusSmall status={"status"}>
                                          {status}
                                        </ProposalStatusSmall>
                                        <DisplayOnlyAboveSmall>
                                          <div
                                            style={{
                                              color: "#888D9B",
                                              fontWeight: "normal",
                                              fontSize: 12,
                                              paddingLeft: 5,
                                            }}
                                          >
                                            {new Date(
                                              timestamp,
                                            ).toLocaleString()}
                                          </div>
                                        </DisplayOnlyAboveSmall>
                                      </RowFixed>
                                    </AutoColumn>
                                  </RowFixed>
                                </AutoColumn>
                                <AutoColumn
                                  gap='sm'
                                  justify='flex-start'
                                  style={{
                                    alignContent: "center",
                                    margin: "auto 0 auto 0",
                                  }}
                                >
                                  <RowFixed>
                                    <AutoColumn gap='sm'>
                                      {tokens.map(
                                        (
                                          { token, amountNum, amountUSD },
                                          i,
                                        ) => {
                                          if (!token) return;
                                          const { name, decimals, symbol } =
                                            token;
                                          console.log({
                                            token,
                                            amountNum,
                                            amountUSD,
                                            decimals,
                                          });
                                          return (
                                            <RowFixed key={i}>
                                              <TokenLogo
                                                name={symbol}
                                                size={40}
                                              />
                                              <ResponsiveBodyText
                                                style={{ marginLeft: 5 }}
                                              >
                                                {amountNum !== null &&
                                                  decimals !== null &&
                                                  `${nFormatter(
                                                    parseFloat(
                                                      utils.formatUnits(
                                                        BigNumber.from(
                                                          amountNum,
                                                        ),
                                                        18,
                                                      ),
                                                    ),
                                                    2,
                                                  )}`}
                                                {amountUSD &&
                                                  `$${nFormatter(
                                                    parseFloat(
                                                      utils.formatUnits(
                                                        amountUSD,
                                                        30,
                                                      ),
                                                    ),
                                                    2,
                                                  )}`}
                                                <DisplayOnlyAboveSmall>
                                                  {amountUSD && ``} {symbol}{" "}
                                                </DisplayOnlyAboveSmall>
                                              </ResponsiveBodyText>
                                            </RowFixed>
                                          );
                                        },
                                      )}
                                    </AutoColumn>
                                  </RowFixed>
                                </AutoColumn>
                                <DisplayOnlyAboveSmall>
                                  <AutoColumn>
                                    <RowFixed
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        margin: "auto",
                                        height: 50,
                                      }}
                                    >
                                      <ButtonAction disabled={true}>
                                        Claim Rewards
                                      </ButtonAction>
                                    </RowFixed>
                                  </AutoColumn>
                                </DisplayOnlyAboveSmall>
                              </RowBetween>
                              {i !== actionsFiltered.length - 1 && (
                                <Break style={{ marginTop: "24px" }} />
                              )}
                            </div>
                          );
                        },
                      )
                    ) : userPayouts ? (
                      <TYPE.body>
                        No past rewards. This may take 1-3 days to update.
                      </TYPE.body>
                    ) : (
                      <Loader />
                    )}
                  </AutoColumn>
                </WhiteCard>
                <Row gap='1rem' align='flex-start'>
                  <RefCodesStatWhiteCard
                    protocolID={protocolID}
                    account={account}
                  />
                  {/* just to show idea of layout I added this below */}
                  <WhiteCard>
                    <AutoColumn gap='md'>
                      <AutoColumn gap='sm'>
                        <TYPE.main fontSize='16px'>Information</TYPE.main>
                        <TYPE.main fontSize='14px'>
                          Here&apos;s how it works
                        </TYPE.main>
                      </AutoColumn>
                      <Break />
                      <p>
                        Payouts for GMX happen on wednesdays and are dispersed
                        on thursdays by AmpliFi multisig. Referrers earn
                        7.5/12.5/15 % of GMX trading fees on tiers 1, 2, 3
                        respectively.
                      </p>
                    </AutoColumn>
                  </WhiteCard>
                </Row>
              </AutoColumn>
            ) : (
              <Card padding='0px'>
                <EmptyWrapper>
                  <TYPE.body style={{ marginBottom: "8px" }}>
                    Connect your wallet to view rewards
                  </TYPE.body>
                  <TYPE.subHeader>
                    This page is in progress. We are actively testing. You will
                    be able to claim your rewards soon!
                  </TYPE.subHeader>
                </EmptyWrapper>
              </Card>
            )}
          </ErrorBoundary>
        </LowerBodyWrapper>
      </AutoColumn>
    </BodyWrapper>
  );
}

export default withRouter(PayoutInfo);

function RefCodesStatWhiteCard(props: {
  protocolID: string;
  account?: string | null;
}) {
  const { protocolID, account } = props;
  const { data, isLoading, isError, error } = useRefCodesStat(
    protocolID,
    account?.toLowerCase(),
  );
  const getSortedData = React.useCallback((data: RewardCodeMap) => {
    return [...data].sort(
      ([, discountUSDA], [, discountUSDB]) => discountUSDB - discountUSDA,
    );
  }, []);

  return (
    <WhiteCard>
      <AutoColumn gap='md'>
        <AutoColumn gap='sm'>
          <TYPE.main fontSize='16px'>GMX referral codes</TYPE.main>
          <RowBetween>
            {isLoading && <Loader />}
            {data &&
              data.headers.map((each, i) => (
                <TYPE.main fontSize='14px' key={each.header}>
                  {each.header}
                </TYPE.main>
              ))}
          </RowBetween>
        </AutoColumn>
        <Break />
        {isLoading && (
          <LoadingRows>
            <div />
          </LoadingRows>
        )}
        {isError && <p>Something went wrong</p>}
        {data &&
          getSortedData(data.data).map(([referralCode, discountUSD], i) => {
            return (
              <RowBetween key={i}>
                <p>{referralCode}</p>
                <p>~{nFormatter(discountUSD, 3)}$</p>
              </RowBetween>
            );
          })}
      </AutoColumn>
    </WhiteCard>
  );
}
