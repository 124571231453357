import React, { UIEventHandler } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useProtocolUpdate } from "../hooks/useProtocolUpdate";
import { BodyWrapper, LowerBodyWrapper, WhiteHeader } from "./AppBody";
// import { useActiveWeb3React } from '../hooks'
import { useActiveProtocol } from "../state/governance/hooks";
import Column, { AutoColumn } from "../components/Column";
import { Above1080Only, Below1080Only } from "../theme/components";
import Row, { RowBetween, RowFixed } from "../components/Row";
import { WrappedListLogo } from "../components/governance/styled";
import Dropdown from "../components/governance/Dropdown";
import Tabs from "../components/governance/Tabs";
import { TYPE } from "../theme";
import CampaignList from "components/campaigns/CampaignList";
import CampaignDetails from "components/campaigns/CampaignDetails";
import { useActiveCampaign } from "state/campaigns/hooks";
import CRE8RPriceCard from "components/CRE8RPriceCard";
import ClaimButton from "../components/ClaimButton/ClaimAGGButton";
import Breadcrumb from "components/Breadcrumb";
import TopBar from "components/TopBar";

function Campaigns({
  match: {
    params: { protocolID, campaignID },
  },
  history,
}: RouteComponentProps<{ protocolID?: string; campaignID?: string }>) {
  // if valid protocol id passed in, update global active protocol
  useProtocolUpdate(protocolID);
  // if on testnet, show warning
  //const { chainId } = useActiveWeb3React()

  const [activeProtocol] = useActiveProtocol();
  const [activeCampaign] = useActiveCampaign();
  return (
    <BodyWrapper>
      <AutoColumn gap='1rem'>
        <TopBar />
        {campaignID && campaignID === activeCampaign?.id && (
          <Tabs history={history} />
        )}
        <Column>
          <LowerBodyWrapper>
            {campaignID ? <CampaignDetails /> : <CampaignList />}
          </LowerBodyWrapper>
        </Column>
      </AutoColumn>
    </BodyWrapper>
  );
}

export default withRouter(Campaigns);
