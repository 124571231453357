import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { LANDING_PAGE_QUERY } from "apollo/queries";
import { PagesQuery } from "generated/wp/graphql";
import {
  ApolloClient,
  ApolloQueryResult,
  NormalizedCacheObject,
} from "@apollo/client";
import { useCre8rCmsClient } from "state/application/hooks";

interface LandingData {
  data: PagesQuery | undefined;
  isError: boolean;
  error: string | undefined;
  isLoading: boolean;
}

export function useLanding() {
  const cmsClient = useCre8rCmsClient();
  return useQuery({
    queryKey: ["landing"],
    queryFn: async () => {
      const data = await fetchLanding(cmsClient);
      if (!data.data) throw new Error("No data came and promise got resolve");
      await new Promise((resolve) => setTimeout(resolve, 1500));
      return data;
    },
  });
}

// export function useLanding(): ApolloQueryResult<PagesQuery> | undefined {
//   const [state, setState] = useState<ApolloQueryResult<PagesQuery>>();
//   const cmsClient = useCre8rCmsClient();
//   useEffect(() => {
//     fetchLanding(cmsClient)
//       .then((res) => {
//         const landingResult = res;
//         setState(landingResult);
//       })
//       .catch((e) => console.error(e));
//   }, [cmsClient]);
//   return state;
// }

async function fetchLanding(
  client: ApolloClient<NormalizedCacheObject>,
): Promise<ApolloQueryResult<PagesQuery>> {
  const res = await client.query({
    query: LANDING_PAGE_QUERY,
    fetchPolicy: "cache-first",
  });
  return res;
}
