import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import config from "config";
import { ChainId } from "../../constants";
export const GMXReferralClientArbitrum = new ApolloClient({
  link: new HttpLink({
    uri: config.api.referralSubgraphs.gmx[ChainId.Arbitrum], // what should i put in here?
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
});

export const GMXReferralClientAvalanche = new ApolloClient({
  link: new HttpLink({
    uri: config.api.referralSubgraphs.gmx[ChainId.Avalanche], // what should i put in here?
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
});
