import { useWeb3React } from "@web3-react/core";
import { ButtonBasic } from "components/Button";
import React from "react";
import { useToggleModal } from "state/application/hooks";
import styled from "styled-components";
import { TYPE } from "theme";
import { ApplicationModal } from "state/application/actions";
import { useArcx } from "hooks/Arcx";
import { ButtonPrimarySmall } from "./GMXReferralModal";

import GMXReferralModal from "./GMXReferralModal";
import ReferralLinks from "./ReferralLinks";

const ButtonText = styled(TYPE.white)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
  `};
`;

const LoggedOutCard = styled.div<{ numOfLinks?: number }>`
  font-size: 15px;
  padding: 1rem;
  background-color: ${({ theme }) => theme.white};
  border-radius: 12px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.primary1};
  color: ${({ theme }) => theme.primary1};
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  width: ${({ numOfLinks }) =>
    numOfLinks ? (99 / numOfLinks).toString() + "%" : "100%"};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    flex-gap: 10px;
  `};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    flex-direction: column;
  `};
`;
//todo error handling
export default function Referral(props: { campaignID: string }) {
  const { campaignID } = props;
  const { account } = useWeb3React();
  const acrx = useArcx();

  const toggleWalletModal = useToggleModal(ApplicationModal.WALLET);
  // did consider if we did want to make this wp is first class
  // probably not because any custom modal will need code changes anyways
  // a case where it makes sense for wp is that there is a need to change
  // the modal/ disable the modal, and doing that in wp could make sense
  if (!account) {
    return (
      <LoggedOutCard>
        <TYPE.blue>Earn with AmpliFi by sharing referral links</TYPE.blue>
        <ButtonPrimarySmall
          onClick={() => {
            acrx?.event("Connect wallet in tabs");
            toggleWalletModal();
          }}
        >
          Connect wallet
        </ButtonPrimarySmall>
      </LoggedOutCard>
    );
  }
  if (
    campaignID.includes("gmx-tier") ||
    campaignID.includes("gmx-referral-campaign")
  ) {
    return <GMXReferralModal />;
  }
  return <ReferralLinks />;
}
