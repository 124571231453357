import React from "react";
import styled from "styled-components";
import { TYPE, Button } from "theme";
import { Link } from "react-router-dom";
import { useCampaign } from "hooks/useCampaign";
import { PageData } from "hooks/useWP";
import { AutoColumn } from "components/Column";
import { RowBetween, RowFixed } from "components/Row";
import { useAutoAnimate } from "hooks/useAutoAnimate";
import Highlights from "./Highlights";
import { ApolloQueryResult } from "@apollo/client";
import { GetNodeByUriQuery } from "generated/wp/graphql";
import laggMultiImage from "assets/images/lagg_direction.gif";
import { TbAffiliate } from "react-icons/tb";
import { RiRefund2Line } from "react-icons/ri";
const CARD_HEIGHT = "80vh";
const CARD_BORDER_PADDING = "30px";

const ImageContainer = styled.div`
  display: block;
  position: relative;
  text-align: center;
  color: white;
  height: ${CARD_HEIGHT};
`;
const CardWrapper = styled.div`
  display: block;
  width: 100%;
`;

const DescWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  text-align: start;
  overflow: hidden;
`;

const ImagePlaceholder = styled.div`
  background-size: stretch;
  background-repeat: no-repeat;
  background-position: center;
  background-color: red;
  width: 100%;
  align-self: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    background-size: contain;
    width: 100%;
    margin-top: 16px;
    min-width: unset;
  `};
`;

const AbsoluteCenter = styled.div`
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 ${CARD_BORDER_PADDING};
  width: 100%;
  ${ImageContainer}:hover & {
    top: 20%;
  }
  transition: 0.4s;
`;

const AbsoluteBottom = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

const AbsoluteMid = styled.div`
  position: absolute;
  bottom: 45%;
  width: 100%;
  padding: 0 ${CARD_BORDER_PADDING};

  opacity: 0;
  transform: translateY(40px);
  ${ImageContainer}:hover & {
    opacity: 1;
    transform: translateY(0px);
  }
  transition: 0.5s;
`;
const AbsoluteBelowMid = styled.div`
  position: absolute;
  top: 50%; // was 45%
  padding: 0 ${CARD_BORDER_PADDING};
  transform: translateY(40px);
  opacity: 0;
  ${ImageContainer}:hover & {
    opacity: 1;
    transform: translateY(0px);
  }
  transition: 0.5s;
`;
const StatsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap-reverse;
  overflow: hidden;
  /* max-height: 150px;  // not sure if/why we need max height? */
  // background-color: red;
  width: 100%;
`;

const SingleCard = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 12px;
  padding-left: 2%;
  align-items: center;
  min-width: 200px;
  /* max-width: 250px; */
`;

const ImageWrapper = styled.div<{ imageUrl: string }>`
  border-radius: 8px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -99;
  background-image: linear-gradient(to bottom, #23232311, #232323),
    url(${({ imageUrl }) => imageUrl});
  min-width: 100%;
  height: ${CARD_HEIGHT};
  align-self: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    background-size: cover;
    width: 100%;
    min-width: unset;
  `};
  animation-duration: 4s;
  ${ImageContainer}:hover && {
    background-image: linear-gradient(to bottom, #23232311, #232323 40%),
      url(${({ imageUrl }) => imageUrl});
  }
  transition: background-image 2s ease-out 100ms;
`;

const MyButton = styled(Button)`
  width: fit-content;
  height: 28px;
  background: #0a0a0a;
  border-radius: 4px;
  border: 1px solid;
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  padding: 4px 10px 24px;

  ${ImageContainer}:hover & {
    background: #fe532d;
    color: #232323;
  }
  transition: 0.5s;
`;

const BottomSection = styled.div`
  background: #0a0a0a;

  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: ${CARD_BORDER_PADDING};
  padding-left: ${CARD_BORDER_PADDING};
  border-radius: 0 0 8px 8px;
  width: 100%;
  ${ImageContainer}:hover & {
    background: #fe532d;
  }
  transition: 0.5s;
`;

const TextAnimate = styled.div`
  color: #fff;
  font-size: 14px;
  font-weight: bolder;
  ${ImageContainer}:hover & {
    color: #232323;
  }
  transition: 0.5s;
`;

const LaggMultiplier = styled.div`
  background-color: #4e4e4e;
  float: right;
  display: flex;
  align-items: center;
  margin: 5px;
  flex-direction: row;
  /* width: 400px; */
  font-size: 13px;
  /* border: 1px solid #fe532d; */
  padding: 10px;
  justify-content: space-between;
  border-radius: 20px;
  height: 40px;
`;

const LaggMultiplier2 = styled.div`
  background-color: #4e4e4e;
  float: right;
  display: flex;
  align-items: center;
  margin: 5px;
  flex-direction: row;
  /* width: 400px; */
  font-size: 13px;
  /* border: 1px solid #fe532d; */
  padding: 2px;
  justify-content: space-between;
  border-radius: 20px;
  height: 40px;
`;

function CampaignCard({
  acField,
  protocolID,
  amplifiCampaign,
}: {
  acField: ApolloQueryResult<GetNodeByUriQuery>;
  protocolID: string;
  amplifiCampaign: PageData;
}) {
  const { uriToRouteMap } = useCampaign(protocolID);

  if (
    !acField.data.nodeByUri ||
    acField.data.nodeByUri.__typename !== "AmpliFiCampaign"
  ) {
    return null;
  }
  const amplifiCampaignFields = acField.data.nodeByUri.amplifiCampaignFields!;
  const highlightsData = amplifiCampaignFields.highlights;

  const description = amplifiCampaignFields.description;
  const featuredImage = amplifiCampaignFields.featuredImage;

  const laggMulti = amplifiCampaignFields.laggMultiplier;
  const laggProRata = amplifiCampaignFields.laggProRata;

  const oneOfLaggData = laggMulti || laggProRata;
  return (
    <CardWrapper>
      {featuredImage && highlightsData && uriToRouteMap[amplifiCampaign.uri] ? (
        <ImageContainer as={Link} to={uriToRouteMap[amplifiCampaign.uri][0]}>
          <ImageWrapper imageUrl={featuredImage.sourceUrl!}>
            <div
              style={{
                justifyContent: "space-between",
                display: "flex",
                alignItems: "center",
                padding: "10px",
                paddingTop: "5px",
                width: "100%",
              }}
            >
              {oneOfLaggData && (
                <div
                  style={{
                    justifyContent: "space-between",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    backgroundColor: "#323232",
                    borderRadius: "10000px",
                  }}
                >
                  {laggMulti && (
                    <LaggMultiplier>
                      <TbAffiliate size={20} style={{ flexGrow: 0 }} />
                      &nbsp;
                      <div style={{ color: "#fe532d", fontWeight: "bolder" }}>
                        {laggMulti}
                      </div>
                      &nbsp;
                    </LaggMultiplier>
                  )}
                  <LaggMultiplier2>
                    <img
                      style={{
                        width: 35,
                      }}
                      src={laggMultiImage}
                    />
                  </LaggMultiplier2>
                  {laggProRata && (
                    <LaggMultiplier>
                      <RiRefund2Line size={20} style={{ flexGrow: 0 }} />
                      &nbsp;
                      <div style={{ color: "#fe532d", fontWeight: "bolder" }}>
                        {laggProRata}
                      </div>
                      &nbsp;
                    </LaggMultiplier>
                  )}
                </div>
              )}
            </div>
          </ImageWrapper>
          <AbsoluteCenter>
            <TYPE.largeHeader
              style={{
                fontWeight: 1600,
                fontSize: "2rem",
                lineHeight: "50px",
                textAlign: "center",
                // width: 450,
              }}
            >
              {amplifiCampaign.title}
            </TYPE.largeHeader>
          </AbsoluteCenter>
          <AbsoluteMid>
            <StatsContainer>
              <SingleCard>
                <AutoColumn>
                  <RowFixed>
                    <Highlights
                      data={highlightsData as any}
                      iconColor='white'
                      showInfo={false}
                    />
                  </RowFixed>
                </AutoColumn>
              </SingleCard>
            </StatsContainer>
          </AbsoluteMid>
          <AbsoluteBelowMid>
            <DescWrapper>
              <div>
                <div
                  style={{
                    fontSize: "14px",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: description!,
                  }}
                />
              </div>
            </DescWrapper>
          </AbsoluteBelowMid>

          <AbsoluteBottom>
            <BottomSection>
              <RowBetween>
                <MyButton>View Campaign</MyButton>
                <TextAnimate>Get Gains</TextAnimate>
              </RowBetween>
            </BottomSection>
          </AbsoluteBottom>
        </ImageContainer>
      ) : (
        <ImagePlaceholder />
      )}
    </CardWrapper>
  );
}

export default CampaignCard;
