import { ArcxAnalyticsSdk } from "@arcxmoney/analytics";

if (!process.env.REACT_APP_ARCX_API_KEY) {
  console.warn("REACT_APP_ARCX_API_KEY is missing. Please add to .env");
}
const ARCX_API_KEY = process.env.REACT_APP_ARCX_API_KEY || "";

let client: ArcxAnalyticsSdk;

export async function getArcxClient() {
  if (!ARCX_API_KEY) {
    console.error("ARCX_API_KEY environment variable not set.");
    return;
  }

  if (client) return client;

  client = await ArcxAnalyticsSdk.init(ARCX_API_KEY, {
    trackPages: true, // default - automatically trigger PAGE event if the url changes after click
    cacheIdentity: true, // default - caches identity of users in their browser's local storage
  });

  return client;
}
