import React, { useMemo, useState } from "react";
import Toggle from "components/Toggle";
import { useLeaderboard } from "hooks/useLeaderboard";
import styled from "styled-components";
import EmptyProfile from "../../assets/images/emptyprofile.png";
import {
  useActiveProtocol,
  useFilterActive,
} from "../../state/governance/hooks";
import { FETCHING_INTERVAL } from "../../state/governance/reducer";
import {
  BlankInternalLink,
  OnlyAboveLarge,
  OnlyAboveSmall,
  TYPE,
} from "../../theme";
import { shortenAddress } from "../../utils";
import Card, { WhiteCard } from "../Card";
import Column, { AutoColumn } from "../Column";
import {
  EmptyWrapper,
  RoundedProfileImage,
  WrappedListLogo,
} from "../governance/styled";
import { LoadingRows } from "../Loader";
import Row, { AutoRow, RowBetween, RowFixed } from "../Row";
import { useAllIdentities, useTwitterProfileData } from "state/social/hooks";
import useENSName from "hooks/useENSName";
import { nameOrAddress } from "utils/getName";
import { RewardData } from "data/leaderboard";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getAddress } from "ethers/lib/utils";
import { useDashboard } from "hooks/useDashboard";
import { RecentActivityData } from "data/dashboard";
import { ago } from "utils/time";
import { BiWalletAlt } from "react-icons/bi";
import { FaPeopleArrows } from "react-icons/fa";
import { AiOutlineDollar } from "react-icons/ai";
const ColumnLabel = styled(TYPE.darkGray)`
  white-space: no-wrap;
  font-size: 15px;
`;

const NoWrap = styled(TYPE.black)`
  white-space: nowrap;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const BodyDataRow = styled(RowBetween)`
  background-color: ${({ theme }) => theme.white};
  margin-bottom: 8px;
  border-radius: 8px;
  // &:hover {
  //   background-color: ${({ theme }) => theme.primary1};
  //   cursor: pointer;
  // }
  transition: 0.5s;
  padding-left: 18px;
  padding-right: 18px;
`;

const AccountLinkGroup = styled(AutoRow)`
  :hover {
    /* opacity: 0.5; */
    /* border-radius: 8px; */
  }
  flex-wrap: nowrap;
`;

const VoteText = styled(NoWrap)`
  width: 120px;
  color: white;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
  `};
`;

const FixedRankWidth = styled.div`
  width: 12px;
  text-align: right;
  margin-right: 0px;
`;

const HiddenBelow1080 = styled.span`
  @media (max-width: 1080px) {
    display: none;
  }
`;

const ResponsiveText = styled(TYPE.black)`
  color: white;
  font-weight: 500px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
  `};
`;

const WhiteHover = styled.div`
  color: #0a0a0a;
  // ${BodyDataRow}:hover & {
  //   color: white;
  // }
  transition: 0.5s;
`;

export const Break = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.bg3};
  height: 1px;
  margin: 12px 0;
`;

function DashboardOverview({
  match: {
    params: { protocolID, campaignID, tabID },
  },
}: RouteComponentProps<{
  protocolID: string;
  campaignID: string;
  tabID?: string;
}>) {
  const [page] = useState(1);
  const { data, isError, isLoading, error } = useDashboard(
    protocolID,
    campaignID,
    tabID,
  );

  //TODO: add pagination support
  const RecentActivityRow = ({
    d,
    i,
  }: {
    d: RecentActivityData;
    i: number;
  }) => {
    return (
      <AutoColumn>
        <BodyDataRow>
          <AutoRow gap='10px' style={{ flexWrap: "nowrap" }}>
            <AccountLinkGroup gap='10px' width='initial'>
              <HiddenBelow1080>
                <WrappedListLogo
                  src={EmptyProfile}
                  alt='profile'
                  style={{ opacity: "0.2" }}
                />
              </HiddenBelow1080>
              <AutoColumn gap='6px'>
                <WhiteHover
                  style={{
                    fontSize: 14,
                    fontWeight: 300,
                  }}
                >
                  {d.description}
                </WhiteHover>
              </AutoColumn>
            </AccountLinkGroup>
          </AutoRow>
          <NoWrap textAlign='end'>
            <WhiteHover
              style={{
                fontSize: 12,
                opacity: "0.6",
                fontWeight: 300,
              }}
            >
              {ago(d.timestamp)}
            </WhiteHover>
          </NoWrap>
        </BodyDataRow>
      </AutoColumn>
    );
  };
  if (isLoading) {
    return (
      <LoadingRows>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </LoadingRows>
    );
  }
  if (isError) {
    return (
      <Card padding='20px'>
        <EmptyWrapper>
          <TYPE.body style={{ marginBottom: "8px" }}>
            Error happened! Contact AmpliFi DAO
          </TYPE.body>
          <TYPE.subHeader>
            <i>{error as React.ReactNode}</i>
          </TYPE.subHeader>
        </EmptyWrapper>
      </Card>
    );
  }
  if (!data) {
    return (
      <Card padding='20px'>
        <EmptyWrapper>
          <TYPE.body style={{ marginBottom: "8px" }}>No payouts yet.</TYPE.body>
          <TYPE.subHeader>
            <i>Rewards for participating will appear here</i>
          </TYPE.subHeader>
        </EmptyWrapper>
      </Card>
    );
  }
  return (
    <Card padding='0'>
      {data.highlights && data.highlights.length ? (
        <>
          <OnlyAboveSmall>
            <RowBetween
              style={{ marginBottom: "32px", alignItems: "flex-start" }}
            >
              <div>
                <TYPE.body fontSize='16px' fontWeight='600'>
                  {data.title}
                </TYPE.body>
                {data.description && (
                  <TYPE.body
                    fontSize='10px'
                    fontWeight={"400"}
                    paddingTop={"10px"}
                  >
                    {data.description}
                  </TYPE.body>
                )}
              </div>
            </RowBetween>
          </OnlyAboveSmall>
          <Row paddingBottom={"8px"} marginBottom={"16px"} gap={"8px"}>
            {data.highlights.map(({ title, formattedStat, stat, icon }, i) => (
              <WhiteCard maxWidth={"200px"} key={i}>
                <Column
                  style={{
                    gap: "8px",
                  }}
                >
                  <AutoRow>
                    {"BiWalletAlt" === icon && <BiWalletAlt size={34} />}
                  </AutoRow>
                  <AutoRow>
                    <TYPE.body fontSize={24} fontWeight={1000}>
                      {formattedStat}
                    </TYPE.body>
                  </AutoRow>
                  <AutoRow>
                    <TYPE.body fontSize={12} fontWeight={600}>
                      {title}
                    </TYPE.body>
                  </AutoRow>
                </Column>
              </WhiteCard>
            ))}
          </Row>
        </>
      ) : null}
      <AutoColumn gap='0'>
        {data ? (
          <>
            <OnlyAboveSmall>
              <TYPE.body fontSize='16px' fontWeight='600'>
                Recent Activity
              </TYPE.body>
              <Break />
            </OnlyAboveSmall>
            {data.recentActivity.length > 0 ? (
              data.recentActivity.map((j, i) => {
                return <RecentActivityRow i={i} d={j} key={i} />;
              })
            ) : (
              <>
                {" "}
                <Card padding='0px'>
                  <EmptyWrapper>
                    <TYPE.body style={{ marginBottom: "8px" }}>
                      No one has been directly linked here.
                    </TYPE.body>
                    <TYPE.subHeader>
                      Try reloading the page and be the first visitor! 😊
                    </TYPE.subHeader>
                  </EmptyWrapper>
                </Card>
              </>
            )}
          </>
        ) : (
          <LoadingRows>
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
          </LoadingRows>
        )}
      </AutoColumn>
    </Card>
  );
}

export default withRouter(DashboardOverview);
