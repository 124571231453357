import { referralClient } from "apollo/client";
import { REFEREES_QUERY } from "apollo/queries";
import axios from "axios";
import { RefereesQuery } from "generated/graphql";
import { RewardData, RewardList } from "..";

// TODO: refactor to Promise.all
async function fetchPixelsReferrals(): Promise<RewardList> {
  const headers = [
    { header: "Wallet connects" },
    { header: "Valid referrals", info: "Verefied with pixels db" },
    { header: "Percent share", info: "how much of airdrop will you get" },
  ];
  const { data } = await referralClient.query<RefereesQuery>({
    query: REFEREES_QUERY,
    variables: {
      protocolId: "PIXELS",
      slug: "pixel-guild-onboarding",
    },
  });
  const rewardData: RewardData[] = [];

  if (data && data.referees) {
    const aggregatedRewardData = new Map<
      string,
      {
        walletConnects: number;
        referrals: number;
        percentShare: number;
      }
    >();

    //clean bad data
    const referees = data.referees.filter(
      ({ address, referrerAddress }) => address && referrerAddress,
    );
    //intialize
    referees.forEach(({ address, referrerAddress }) => {
      aggregatedRewardData.set(referrerAddress, {
        percentShare: 0,
        referrals: 0,
        walletConnects: 0,
      });
    });
    // count wallet connects
    referees.forEach(({ referrerAddress }) => {
      const rewardPoint = aggregatedRewardData.get(referrerAddress)!; // because we intialized so we know it exists
      aggregatedRewardData.set(referrerAddress, {
        ...rewardPoint,
        walletConnects: rewardPoint.walletConnects + 1,
      });
    });
    //valid referrals
    let totalValidReferrals = 0;
    const res = await axios.get(
      "https://pixels-data.herokuapp.com/v1/airdrop/points?eth=0x069e85D4F1010DD961897dC8C095FBB5FF297434",
    );
    if (res.status === 200) {
      const pixelReferrals: string[] = res.data.referralEths;
      pixelReferrals.forEach((referralAddress) => {
        let ampliFiReferrerAddress = "";
        referees.forEach(({ address, referrerAddress }) => {
          if (address === referralAddress) {
            console.log("fasdfasdf");
            ampliFiReferrerAddress = referrerAddress;
            return true;
          }
          return false;
        });
        if (ampliFiReferrerAddress) {
          totalValidReferrals += 1;
          const rewardPoint = aggregatedRewardData.get(ampliFiReferrerAddress)!;
          aggregatedRewardData.set(ampliFiReferrerAddress, {
            ...rewardPoint,
            referrals: rewardPoint.referrals + 1,
            walletConnects: rewardPoint.walletConnects + 1,
          });
        }
      });
    }
    // percent share
    if (totalValidReferrals > 0) {
      [...aggregatedRewardData].forEach(([address, d]) => {
        const rewardPoint = aggregatedRewardData.get(address)!;
        aggregatedRewardData.set(address, {
          ...rewardPoint,
          percentShare: (d.referrals / totalValidReferrals) * 100,
        });
      });
    }
    [...aggregatedRewardData].forEach(([address, d]) => {
      const walletConnects = d.walletConnects.toString();
      const percentShare = d.percentShare.toString();
      const referrals = d.referrals.toString();
      rewardData.push({
        address: address,
        rewards: [walletConnects, referrals, percentShare],
        defaultSortValue: percentShare,
        formattedRewards: [walletConnects, referrals, percentShare + "%"],
      });
    });
  }
  return {
    headers: headers,
    data: rewardData,
  };
}

export default [
  {
    title: "Pixels Leaderboard",
    description:
      "Valid referrals takes 24 hours after a user have connected their premint.",
    fetch: fetchPixelsReferrals,
  },
];
