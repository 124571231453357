import { useWeb3React } from "@web3-react/core";
import { useMemo } from "react";
import { getProviderOrSigner } from "utils";
import { IReferralStorage__factory } from "./factories/IReferralStorage__factory";
import { Web3Provider } from "@ethersproject/providers";
import { ChainId } from "../../constants";

const referralStorage = {
  [ChainId.Arbitrum]: "0xe6fab3f0c7199b0d34d7fbe83394fc0e0d06e99d", //arbitrum
  [ChainId.Avalanche]: "0x827ED045002eCdAbEb6e2b0d1604cf5fC3d322F8", //avalanche
};

export const useGMXReferralContract = (withSignerIfPossible = false) => {
  const { account, library, chainId } = useWeb3React<Web3Provider>();
  return useMemo(() => {
    if (!library) return;
    const provider = getProviderOrSigner(
      library,
      withSignerIfPossible && account ? account : undefined,
    );
    if (ChainId.Arbitrum == chainId || ChainId.Avalanche == chainId) {
      return IReferralStorage__factory.connect(
        // @ts-ignore
        referralStorage[chainId],
        // @ts-ignore
        provider,
      );
    }
    return null;
  }, [account, library, withSignerIfPossible]);
};
