import { ArcxAnalyticsSdk } from "@arcxmoney/analytics";
import { ArcxContext } from "hooks/Arcx";
import { getArcxClient } from "utils/arcx";
import { useEffect, useRef } from "react";
import { useWeb3React } from "@web3-react/core";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getUTMTags } from "utils/utm";

export const ArcxProvider = ({ children }: { children: React.ReactNode }) => {
  const arcx = useRef<ArcxAnalyticsSdk | undefined>();
  const locationTracker = useLocation();
  const history = useHistory();
  const { account, chainId } = useWeb3React();
  useEffect(() => {
    getArcxClient().then((arcxClient) => {
      arcx.current = arcxClient;
    });
  }, []);

  useEffect(() => {
    if (!locationTracker) return;
    const search = location.search;
    const { utm_campaign, utm_content, utm_source } = getUTMTags(search);
    console.log("I got here");
    arcx.current
      ?.attribute({
        campaignId: utm_campaign, // campaign name
        source: utm_source, // protocol id
        content: utm_content, // referrer address
      })
      .then((res) => console.log("attribute1", res))
      .catch((res) => console.log("catchattribute1", res));
    arcx.current
      ?.event("CUSTOM_UTM", {
        utm_campaign,
        utm_content,
        utm_source,
      })
      .then((res) => console.log("attribute2", res));
  }, [arcx.current, locationTracker]);
  useEffect(() => {
    if (!account) return;

    arcx.current?.connectWallet({
      account,
      chain: chainId || "0",
    });
  }, [account]);

  return (
    <ArcxContext.Provider value={arcx.current}>{children}</ArcxContext.Provider>
  );
};
