import {
  GMXReferralClientAvalanche,
  GMXReferralClientArbitrum,
  payoutsClient,
} from "apollo/client";
import config from "config";
// number of referrers
import { RefereesQuery } from "generated/graphql";
import { REFEREES_QUERY } from "apollo/queries";
import { ReferralDataQuery } from "generated/gmx-referral/graphql";
import { REFERRAL_DATA_QUERY } from "apollo/queries/gmx-referral";
import { isAddress } from "utils";
import { RewardData, RewardList } from "..";
import { ChainId } from "../../../constants";
import { ApolloClient } from "@apollo/client";
import { nFormatter } from "utils/format";

const formatUSD = (discountUsd: string) => {
  return (parseFloat(discountUsd) / 1e30).toString();
};

function fetchGMXMetaReferralFactory(chainId: ChainId) {
  return () => {
    switch (chainId) {
      case ChainId.Arbitrum:
        return fetchGMXMetaReferrals(GMXReferralClientArbitrum, chainId);
      case ChainId.Avalanche:
        return fetchGMXMetaReferrals(GMXReferralClientAvalanche, chainId);

      default:
        throw "Chain id must be Avalanche or Arbitrum";
    }
  };
}

async function fetchGMXMetaReferrals(
  client: ApolloClient<any>,
  chainId: ChainId,
): Promise<RewardList> {
  if (chainId !== ChainId.Arbitrum && chainId !== ChainId.Avalanche)
    throw "Chain Id must be Avalanche or Arbitrum";
  const headers = [
    { header: "Affiliates Referred" },
    { header: "Traders Referred" },
    { header: "Total volume" },
    { header: "Estimated earnings" },
  ];
  // get all unique meta referrers
  const gmxMetaRawReferrals = await payoutsClient.query<RefereesQuery>({
    query: REFEREES_QUERY,
    variables: {
      slug: "gmx-meta-referral",
      protocolId: "GMX",
    },
  });

  // get number of traders referred
  const gmxReferralData = await client.query<ReferralDataQuery>({
    query: REFERRAL_DATA_QUERY,
    variables: {
      typeIds: ["1", "2"],
      account: config.multisigWallets[chainId],
      timestamp: parseInt((Date.now() / 1000).toFixed(0)),
      referralTotalStatsId: `total:0:${config.multisigWallets[chainId]}`,
    },
  });
  const filteredData = gmxReferralData.data.referralCodes.filter(
    ({ oldOwner }) => oldOwner,
  );

  const filteredReferrerAddresses = gmxMetaRawReferrals.data.referees
    ?.map((each) => each.referrerAddress)
    .filter((each) => each && each.length > 0 && isAddress(each));
  const listOfReferrers = new Set(filteredReferrerAddresses);
  const dataMap = new Map<string, RewardData>();
  // accumulate affiliate referred for each unique meta referrers
  for (const referrer of listOfReferrers) {
    let affiliatesReferred = 0;
    gmxMetaRawReferrals.data.referees?.forEach((each) => {
      if (each.referrerAddress === referrer) {
        affiliatesReferred++;
      }
    });
    // get total traders referred
    let tradedReferralsCount = "0",
      totalVolume = "0",
      estimatedEarnings = "0";
    for (const refCodeData of filteredData) {
      if (referrer === refCodeData.oldOwner) {
        const gmxOfficialData = gmxReferralData.data.referrerTotalStats.find(
          (totalStatsData) => totalStatsData.referralCode === refCodeData.code,
        );
        if (gmxOfficialData) {
          // accumulate traded referrals
          if (tradedReferralsCount !== "0") {
            tradedReferralsCount = (
              parseInt(tradedReferralsCount) + 1
            ).toString();
          } else {
            tradedReferralsCount = gmxOfficialData.tradedReferralsCount;
          }
          // accumulate total vol
          if (totalVolume !== "0") {
            totalVolume = (
              parseInt(totalVolume) +
              parseInt(
                formatUSD((parseInt(gmxOfficialData.volume) / 2).toString()),
              )
            ).toString();
          } else {
            totalVolume = formatUSD(
              (parseInt(gmxOfficialData.volume) / 2).toString(),
            );
          }
          // accumulate estimated earnings
          if (estimatedEarnings !== "0") {
            estimatedEarnings = (
              parseInt(estimatedEarnings) +
              parseInt(totalVolume) * 0.001 * 0.01
            ).toString();
          } else {
            estimatedEarnings = (
              parseInt(totalVolume) *
              0.001 *
              0.01
            ).toString();
          }
        }
      }
    }
    dataMap.set(referrer, {
      address: referrer,
      rewards: [
        affiliatesReferred.toString(),
        tradedReferralsCount,
        totalVolume,
        estimatedEarnings,
      ],
      formattedRewards: [
        affiliatesReferred.toString(),
        tradedReferralsCount,
        "~$" + nFormatter(parseFloat(totalVolume), 2),
        "~$" + parseFloat(estimatedEarnings).toFixed(4),
      ],
      defaultSortValue: totalVolume.toString(),
    });
  }

  const data = [...dataMap.values()];
  return {
    headers: headers,
    data: data,
  };
}

export default [
  {
    tabName: "Arbitrum Meta",
    title: "Arbitrum GMX Meta Referrals",
    description: "Stats and rewards for the GMX Meta Referral campaign",
    fetch: fetchGMXMetaReferralFactory(ChainId.Arbitrum),
  },
  {
    tabName: "Avalanche Meta",
    title: "Avalanche GMX Meta Referrals",
    description: "Stats and rewards for the GMX Meta Referral campaign",
    fetch: fetchGMXMetaReferralFactory(ChainId.Avalanche),
  },
];
