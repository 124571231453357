import gmx from "./gmx";
import gmxMeta from "./gmxMeta";
import pixels from "./pixels";
import bb from "./boostedbribes";
type HeaderWithInfo = {
  header: string;
  info?: string;
};

// The headers should be ordered from least to most important. For responsiveness, the first value in formattedRewards will be the first to go.
export type RewardList = {
  headers: HeaderWithInfo[]; // example: [{header: "Total CRE8R Rewards"}, {header: "Total AGG Rewards", , info: 'sum of AGG rewards'}]
  data: RewardData[];
};

export type RewardData = {
  rewards: string[]; // ["1000", "1000"] // this is assumed to be in USD or paid in a token valued at a USD
  formattedRewards: (string | JSX.Element)[]; // ie: ["$0 CRE8R", "$0 AGG"]
  defaultSortValue: string; // assumes that this will be parsed into a float
  address: string;
};

export type RewardCodeMap = Map<string, number>;

export type RewardCodeMapData = {
  headers: HeaderWithInfo[];
  data: RewardCodeMap;
  title: string;
  description: string;
};

// Note that leaderboardID is just the index
interface LeaderboardMapper {
  [protocolID_campaignID_leaderboardID: string]: Array<{
    tabName?: string;
    title: string;
    description: string;
    fetch: () => Promise<RewardList>;
    fetchUsersRefCodes?: (account: string) => Promise<RewardCodeMapData>;
  }>;
}

export const leaderboardMapper: LeaderboardMapper = {
  "GMX/gmx-tier-1": [...gmxMeta, ...gmx],
  "GMX/gmx-tier-2": [...gmxMeta, ...gmx],
  "GMX/gmx-tier-3": [...gmxMeta, ...gmx],
  "GMX/gmx-meta-referral": [...gmxMeta, ...gmx],
  "CRE8R/beets-boosted-bribes": pixels,
  "CRE8R/beets-boosted-bribes/beets-boosted-bribes-snapshot-voting-how-to-get-boost":
    bb,
  "PIXELS/pixel-guild-onboarding": pixels,
  "PIXELS/pixels-guild": pixels,
};
